import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Wallet, QrCode, Camera, Telescope, Settings } from 'lucide-react';
import { useMerchantProfile } from '../hooks/queries';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const location = useLocation();
  const { data: isMerchant = false } = useMerchantProfile();

  const footerItems = [
    { to: '/', icon: Wallet, label: 'Cartes' },
    { to: '/add-shop', icon: Telescope, label: 'Découvrir' },
    { to: '/scan', icon: QrCode, label: 'Pass' },
    ...(isMerchant ? [{ to: '/camera', icon: Camera, label: 'Scanner' }] : []),
    { to: '/settings', icon: Settings, label: 'Paramètres' },
  ];

  return (
    <nav
      className={`bg-background border-t fixed bottom-0 left-0 right-0 shadow-lg ${className}`}
    >
      <div
        className={`grid ${
          isMerchant ? 'grid-cols-5' : 'grid-cols-4'
        } gap-1 py-2 px-1`}
      >
        {footerItems.map(item => (
          <Link
            key={item.to}
            to={item.to}
            state={{ from: location.pathname }}
            className={`flex flex-col items-center justify-center ${
              location.pathname === item.to
                ? 'text-primary'
                : 'text-muted-foreground'
            } transition-colors duration-200`}
          >
            <item.icon className="h-5 w-5 mb-1" />
            <span className="text-[10px] font-medium leading-tight">
              {item.label}
            </span>
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Footer;
