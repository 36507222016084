// src/utils/revenueCatUtils.ts
import { Purchases } from '@revenuecat/purchases-capacitor';
import { Purchases as PurchasesWeb } from '@revenuecat/purchases-js';
import { Capacitor } from '@capacitor/core';

export async function checkRevenueCatEntitlements() {
  try {
    let activeEntitlements;
    let subscriptionStatus = null;

    if (Capacitor.isNativePlatform()) {
      // Attendre que Purchases soit configuré
      await new Promise<void>(async resolve => {
        const checkConfiguration = async () => {
          const { isConfigured } = await Purchases.isConfigured();
          if (isConfigured) {
            resolve();
          } else {
            setTimeout(checkConfiguration, 100);
          }
        };
        await checkConfiguration();
      });

      const result = await Purchases.getCustomerInfo();
      activeEntitlements = result.customerInfo.entitlements.active;

      // Extraire les informations sur l'abonnement
      const firstActiveEntitlement = Object.values(activeEntitlements)[0];
      if (firstActiveEntitlement) {
        subscriptionStatus = {
          isActive: firstActiveEntitlement.isActive,
          willRenew: firstActiveEntitlement.willRenew,
          expirationDate: firstActiveEntitlement.expirationDate
            ? new Date(firstActiveEntitlement.expirationDate)
            : null,
        };
      }
    } else {
      // Attendre que PurchasesWeb soit configuré
      await new Promise<void>(resolve => {
        const checkConfiguration = () => {
          try {
            PurchasesWeb.getSharedInstance();
            resolve();
          } catch {
            setTimeout(checkConfiguration, 100);
          }
        };
        checkConfiguration();
      });

      const customerInfo =
        await PurchasesWeb.getSharedInstance().getCustomerInfo();
      activeEntitlements = customerInfo.entitlements.active;

      // Extraire les informations sur l'abonnement
      const firstActiveEntitlement = Object.values(activeEntitlements)[0];
      if (firstActiveEntitlement) {
        subscriptionStatus = {
          isActive: firstActiveEntitlement.isActive,
          willRenew: firstActiveEntitlement.willRenew,
          expirationDate: firstActiveEntitlement.expirationDate
            ? new Date(firstActiveEntitlement.expirationDate)
            : null,
        };
      }
    }

    const hasActiveEntitlement =
      Object.keys(activeEntitlements || {}).length > 0;

    return {
      hasActiveEntitlement,
      entitlements: activeEntitlements,
      subscriptionStatus,
    };
  } catch (error) {
    console.error(
      'Erreur lors de la vérification des droits RevenueCat:',
      error
    );
    return {
      hasActiveEntitlement: false,
      entitlements: null,
      subscriptionStatus: null,
    };
  }
}
