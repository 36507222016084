import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Shop } from '../App';
import { Button } from '../@/components/ui/button';
import { X, Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../@/components/ui/input';
import { Capacitor } from '@capacitor/core';
import iconMarker from '../assets/iconmarker.png';
import './MapPage.css';
import Spinner from 'src/@/components/ui/spinner';
import Fuse from 'fuse.js';
import { useUserLocation } from '../hooks/queries';

// Suppression des icônes par défaut de Leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface MapPageProps {
  shops: Shop[];
}

const MapPage: React.FC<MapPageProps> = ({ shops }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredShops, setFilteredShops] = useState<Shop[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const isIOS = Capacitor.getPlatform() === 'ios';
  const mapRef = useRef<L.Map | null>(null);
  const fuseRef = useRef<Fuse<Shop> | null>(null);

  const defaultCenter: [number, number] = [46.3167, 7.7167];
  const defaultZoom = 8;

  const {
    data: userLocation,
    isLoading,
    error: locationError,
  } = useUserLocation();

  useEffect(() => {
    fuseRef.current = new Fuse(shops, {
      keys: ['name', 'city', 'canton', 'category', 'postalCode'],
      threshold: 0.3,
    });
  }, [shops]);

  useEffect(() => {
    if (locationError) {
      setError(locationError.message);
      if (mapRef.current) {
        mapRef.current.setView(defaultCenter, defaultZoom);
      }
    }
  }, [locationError]);

  useEffect(() => {
    if (mapRef.current && userLocation) {
      mapRef.current.setView(
        [userLocation.latitude, userLocation.longitude],
        13
      );
    }
  }, [userLocation]);

  useEffect(() => {
    if (searchTerm && fuseRef.current) {
      const results = fuseRef.current.search(searchTerm);
      setFilteredShops(results.map(result => result.item));
      setSuggestions(
        results.map(result => {
          const shop = result.item;
          return `${shop.name} - ${shop.city}${
            shop.canton ? `, ${shop.canton}` : ''
          } (${shop.postalCode}) - ${shop.category}`;
        })
      );
    } else {
      setFilteredShops(shops);
      setSuggestions([]);
    }
  }, [searchTerm, shops]);

  const handleSearch = () => {
    if (filteredShops.length > 0 && mapRef.current) {
      const firstShop = filteredShops[0];
      if (
        typeof firstShop.latitude === 'number' &&
        typeof firstShop.longitude === 'number'
      ) {
        mapRef.current.setView([firstShop.latitude, firstShop.longitude], 13);
      }
    }
    setSuggestions([]);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchTerm(suggestion.split(' - ')[0]); // Définir uniquement le nom du magasin comme terme de recherche
    setSuggestions([]); // Fermer les suggestions immédiatement

    // Trouver le magasin correspondant
    const selectedShop = shops.find(
      shop =>
        `${shop.name} - ${shop.city}${shop.canton ? `, ${shop.canton}` : ''} (${
          shop.postalCode
        }) - ${shop.category}` === suggestion
    );

    if (selectedShop && mapRef.current) {
      mapRef.current.setView(
        [selectedShop.latitude, selectedShop.longitude],
        17
      );
    }
  };

  const openInMaps = (shop: Shop) => {
    const address = `${shop.address}, ${shop.postalCode} ${shop.city}, ${shop.country}`;
    const encodedAddress = encodeURIComponent(address);

    const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(
      navigator.userAgent
    );

    if (isAppleDevice) {
      const appleMapsUrl = `maps:0,0?q=${encodedAddress}`;
      window.location.href = appleMapsUrl;
    } else {
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
      window.open(googleMapsUrl, '_blank');
    }
  };

  const customIcon = new L.Icon({
    iconUrl: iconMarker,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  return (
    <div className={`map-page ${isIOS ? 'ios-device' : ''}`}>
      <div className="p-4 bg-white shadow-md z-10 flex justify-between items-center relative">
        <div className="relative flex-grow">
          <Input
            type="text"
            placeholder="Cherchez un magasin, une ville, une catégorie..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}
            className="w-full pl-2 pr-10 py-1 h-12"
          />
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-0 top-1/2 transform -translate-y-1/2 h-12 w-12 rounded-full"
            onClick={handleSearch}
          >
            <Search className="h-6 w-6" />
          </Button>
          {suggestions.length > 0 && (
            <ul className="absolute z-50 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full w-12 h-12 ml-2"
          onClick={() => navigate(-1)}
        >
          <X className="h-6 w-6" />
        </Button>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <Spinner />
        </div>
      ) : (
        <div className="flex-1 relative overflow-hidden">
          <MapContainer
            center={
              userLocation
                ? [userLocation.latitude, userLocation.longitude]
                : defaultCenter
            }
            zoom={userLocation ? 13 : defaultZoom}
            zoomControl={false}
            ref={mapRef}
            className="z-0"
          >
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
            {(searchTerm ? filteredShops : shops).map((shop, index) =>
              typeof shop.latitude === 'number' &&
              typeof shop.longitude === 'number' ? (
                <Marker
                  key={`${shop.id}-${index}`}
                  position={[shop.latitude, shop.longitude]}
                  icon={customIcon}
                >
                  <Popup>
                    <div
                      onClick={() => openInMaps(shop)}
                      className="cursor-pointer"
                    >
                      <strong>{shop.name}</strong>
                      <br />
                      {shop.address}
                      <br />
                      {shop.city}, {shop.postalCode}
                      {shop.canton && (
                        <>
                          <br />
                          {shop.canton}
                        </>
                      )}
                      <br />
                      {shop.category}
                    </div>
                  </Popup>
                </Marker>
              ) : null
            )}
          </MapContainer>
          {error && (
            <p className="absolute bottom-0 left-0 p-4 bg-red-200 text-red-800">
              {error}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default MapPage;
