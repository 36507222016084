import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../@/components/ui/card";
import QRCode from "qrcode.react";
import { Capacitor } from "@capacitor/core";
import { ReactComponent as FideliPassLogo } from "../assets/FideliPass nom.svg";
import { Header } from "src/components/Header";

interface ScanPageProps {
  qrCode: string;
}

const ScanPage: React.FC<ScanPageProps> = ({ qrCode }) => {
  const isNative = Capacitor.isNativePlatform();

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <Header variant="default" className="z-10" />
      <div
        className={`flex-grow flex items-center justify-center p-4 ${
          isNative ? "safe-area-inset-bottom" : ""
        }`}
      >
        <Card>
          <CardHeader>
            <CardTitle>
              <FideliPassLogo className="h-5 w-auto inline" />
            </CardTitle>
          </CardHeader>
          <CardContent className="flex items-center justify-center">
            <QRCode value={qrCode} size={256} />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ScanPage;
