import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '../@/components/ui/button';
import { Input } from '../@/components/ui/input';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../@/components/ui/card';
import { X } from 'lucide-react';
import {
  doc,
  setDoc,
  updateDoc,
  query,
  collection,
  getDocs,
  where,
} from 'firebase/firestore';
import { db, auth, app } from '../firebase';
import { Switch } from '../@/components/ui/switch';
import { Label } from '../@/components/ui/label';
import { Capacitor } from '@capacitor/core';
import { countryMap, getCountryCode } from '../utils/countryUtils';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useQueryClient } from '@tanstack/react-query';

interface GeocodingResponse {
  lat: number;
  lng: number;
}

const categories = [
  { value: 'Restaurant/Bar', label: 'Restaurant et bar' },
  { value: 'Commerce', label: 'Commerce' },
  { value: 'Santé/Beauté', label: 'Santé et beauté' },
  { value: 'Hôtel/Logement', label: 'Hôtel et logement' },
  { value: 'Fitness', label: 'Fitness' },
  { value: 'Culture/Loisirs', label: 'Culture et loisirs' },
  { value: 'Éducation', label: 'Éducation' },
  { value: 'Transport', label: 'Transport' },
  { value: 'Technologie/Innovation', label: 'Technologie et innovation' },
  { value: 'Artisanat', label: 'Artisanat' },
  { value: 'Environnement', label: 'Environnement' },
  { value: 'Services/Publics', label: 'Services publics' },
  { value: 'Finances/Assurance', label: 'Finances et assurance' },
  { value: 'Immobilier', label: 'Immobilier' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Alimentation', label: 'Alimentation' },
  { value: 'Tourisme', label: 'Tourisme' },
  { value: 'Mode', label: 'Mode' },
  { value: 'Divertissement', label: 'Divertissement' },
  { value: 'Télécommunication', label: 'Télécommunication' },
  { value: 'Marketing/Publicité', label: 'Marketing et publicité' },
  { value: 'Juridique', label: 'Juridique' },
  { value: 'Ressources Humaines', label: 'Ressources humaines' },
  { value: 'Sécurité', label: 'Sécurité' },
  { value: 'Industrie', label: 'Industrie' },
  { value: 'Logistique', label: 'Logistique' },
  { value: 'Énergie', label: 'Énergie' },
  { value: 'Informatique', label: 'Informatique' },
  { value: 'Médias', label: 'Médias' },
  { value: 'Pharmaceutique', label: 'Pharmaceutique' },
  { value: 'Automobile', label: 'Automobile' },
  { value: 'Sport', label: 'Sport' },
  { value: 'Autre', label: 'Autres' },
];

const formSchema = z.object({
  name: z.string().min(1, 'Le nom est requis'),
  category: z.string().min(1, 'La catégorie est requise'),
  address: z.string().min(5, "L'adresse doit contenir au moins 5 caractères"),
  postalCode: z.string().min(1, 'Le code postal est requis'),
  city: z.string().min(1, 'La ville est requise'),
  country: z.string().min(1, 'Le pays est requis'),
  canton: z.string().optional(),
  phone: z.string().regex(/^\+?[1-9]\d{1,14}$/, 'Numéro de téléphone invalide'),
  bubbleCount: z.number().min(1).max(20),
  description: z
    .string()
    .max(200, 'La description ne doit pas dépasser 200 caractères')
    .optional(),
  website: z.string().url('URL invalide').optional().or(z.literal('')),
  instagram: z
    .string()
    .url('URL Instagram invalide')
    .optional()
    .or(z.literal('')),
  facebook: z
    .string()
    .url('URL Facebook invalide')
    .optional()
    .or(z.literal('')),
  showPhone: z.boolean().default(true),
  showDescription: z.boolean().default(true),
  showWebsite: z.boolean().default(true),
  showInstagram: z.boolean().default(true),
  showFacebook: z.boolean().default(true),
  offre: z
    .string()
    .min(1, "L'offre est requise")
    .max(160, "L'offre ne doit pas dépasser 160 caractères"),
});

const NewMerchantPage: React.FC = () => {
  const navigate = useNavigate();
  const [isSwiss, setIsSwiss] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  const [isUpdating, setIsUpdating] = useState(false);
  const [dots, setDots] = useState('');
  const queryClient = useQueryClient();

  useEffect(() => {
    const checkExistingMerchant = async () => {
      const user = auth.currentUser;
      if (user) {
        const q = query(
          collection(db, 'merchants'),
          where('userId', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
          if (doc.data().name) {
            navigate('/merchant-profiles');
          }
        });
      }
    };
    checkExistingMerchant();
  }, [navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      category: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      canton: '',
      phone: '',
      bubbleCount: 10,
    },
  });

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value.toLowerCase();
    setIsSwiss(
      value.includes('suisse') ||
        value.includes('schweiz') ||
        value.includes('switzerland')
    );
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Récupérer le shopId existant
        const q = query(
          collection(db, 'merchants'),
          where('userId', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);
        let shopId = '';

        // Vérifier si un document correspondant à l'utilisateur a été trouvé
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            const data = doc.data();
            shopId = data.shopId;
          });
        }

        if (!shopId) {
          console.error('Aucun shopId trouvé pour cet utilisateur');
          return;
        }

        // Préparer les données d'adresse pour le géocodage
        const addressData = {
          address: values.address,
          postalCode: values.postalCode,
          city: values.city,
          country: values.country,
          canton: isSwiss ? values.canton : undefined,
        };

        // Appeler la fonction Cloud pour le géocodage
        const functions = getFunctions(app, 'europe-west6');
        const geocodeAddress = httpsCallable<
          typeof addressData,
          GeocodingResponse
        >(functions, 'geocodeAddress');
        const geocodeResult = await geocodeAddress(addressData);

        const merchantData = {
          ...values,
          userId: user.uid,
          shopId: shopId,
          createdAt: new Date(),
          bubbleCount: Number(values.bubbleCount),
          latitude: geocodeResult.data.lat,
          longitude: geocodeResult.data.lng,
        };
        if (!isSwiss) {
          delete merchantData.canton;
        }

        // Mise à jour du document existant dans merchants
        await updateDoc(doc(db, 'merchants', user.uid), merchantData);

        // Création d'un nouveau document dans globalShops
        const countryCode = getCountryCode(values.country);
        const newShop = {
          id: shopId,
          userId: user.uid,
          name: values.name,
          address: values.address,
          postalCode: values.postalCode,
          city: values.city,
          country: values.country,
          canton: values.canton,
          description: values.description,
          website: values.website,
          instagram: values.instagram,
          facebook: values.facebook,
          phone: values.phone,
          showDescription: values.showDescription,
          showPhone: values.showPhone,
          showWebsite: values.showWebsite,
          showInstagram: values.showInstagram,
          showFacebook: values.showFacebook,
          bubbleCount: Number(values.bubbleCount),
          added: false,
          category: values.category,
          offre: values.offre,
          latitude: geocodeResult.data.lat,
          longitude: geocodeResult.data.lng,
        };

        const globalShopsRef = doc(
          db,
          'globalShops',
          'countries',
          countryCode,
          shopId
        );
        await setDoc(globalShopsRef, newShop);

        await queryClient.invalidateQueries({
          queryKey: ['merchantProfile', user.uid],
        });

        alert('Profil commerçant créé avec succès !');
        navigate('/merchant-profiles');
      } catch (error) {
        console.error(
          'Erreur lors de la création du profil commerçant:',
          error
        );
        alert(
          'Une erreur est survenue lors de la création du profil. Veuillez attendre quelques minutes avant de soumettre à nouveau. Nos serveurs sont en train de créer votre profil de base.'
        );
      }
    } else {
      alert(
        'Vous devez être connecté et abonné pour créer un profil commerçant.'
      );
      navigate('/login');
    }
  };

  useEffect(() => {
    if (isUpdating) {
      const interval = setInterval(() => {
        setDots(prev => (prev.length < 3 ? prev + '.' : ''));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isUpdating]);

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? 'safe-area-inset-top safe-area-inset-left safe-area-inset-right'
          : ''
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <div className="w-12 h-12"></div>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full w-12 h-12"
            onClick={() =>
              navigate('/merchant-profiles', { state: { noAnimation: true } })
            }
          >
            <X className="h-6 w-6" />
          </Button>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto p-4">
        <Card>
          <CardHeader>
            <CardTitle>Nouveau commerce</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 pb-20">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom du commerce
                </label>
                <Input
                  id="name"
                  {...register('name')}
                  className="mt-1 h-12"
                  placeholder="Ex: Le Hibou"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name.message}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Catégorie du commerce
                </label>
                <select
                  {...register('category')}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 bg-gray-100 border focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md h-12"
                >
                  <option value="">Veuillez sélectionner une catégorie</option>
                  {categories.map(category => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </select>
                {errors.category && (
                  <p className="text-red-500 text-sm">
                    {errors.category.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adresse
                </label>
                <Input
                  id="address"
                  {...register('address')}
                  className="mt-1 h-12"
                  placeholder="Ex: Route du Village 42"
                />
                {errors.address && (
                  <p className="text-red-500 text-sm">
                    {errors.address.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="postalCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Code postal
                </label>
                <Input
                  id="postalCode"
                  {...register('postalCode')}
                  className="mt-1 h-12"
                  placeholder="Ex: 1907"
                />
                {errors.postalCode && (
                  <p className="text-red-500 text-sm">
                    {errors.postalCode.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ville ou village
                </label>
                <Input
                  id="city"
                  {...register('city')}
                  className="mt-1 h-12"
                  placeholder="Ex: Saxon"
                />
                {errors.city && (
                  <p className="text-red-500 text-sm">{errors.city.message}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Pays
                </label>
                <select
                  {...register('country')}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200  border bg-gray-100 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md h-12"
                  onChange={e => {
                    handleCountryChange(e);
                    register('country').onChange(e);
                  }}
                >
                  <option value="">Sélectionnez un pays</option>
                  {Object.keys(countryMap).map(country => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <p className="text-red-500 text-sm">
                    {errors.country.message}
                  </p>
                )}
                <p className="mt-1 text-sm text-gray-500">
                  Assurez-vous de sélectionner le bon pays. Pour le modifier
                  ultérieurement, vous devrez nous contacter.
                </p>
              </div>

              {isSwiss && (
                <div>
                  <label
                    htmlFor="canton"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Canton
                  </label>
                  <Input
                    {...register('canton')}
                    placeholder="Ex: Valais"
                    className="mt-1 h-12"
                  />
                  {errors.canton && (
                    <p className="text-red-500 text-sm">
                      {errors.canton.message}
                    </p>
                  )}
                </div>
              )}

              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Numéro de téléphone (indicatif et tout collé)
                </label>
                <Input
                  id="phone"
                  {...register('phone')}
                  className="mt-1 h-12"
                  placeholder="Ex: +41787907979"
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone.message}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="bubbleCount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nombre de tampons (par défaut 10)
                </label>
                <select
                  {...register('bubbleCount', { valueAsNumber: true })}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 bg-gray-100 border focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md h-12"
                >
                  {[...Array(20)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
                {errors.bubbleCount && (
                  <p className="text-red-500 text-sm">
                    {errors.bubbleCount.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="offre"
                  className="block text-sm font-medium text-gray-700"
                >
                  Produit obtenu après carte remplie
                </label>
                <Input
                  id="offre"
                  {...register('offre')}
                  className="mt-1 h-12"
                  placeholder="Ex: Un café"
                  required
                />
                {errors.offre && (
                  <p className="text-red-500 text-sm">{errors.offre.message}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description (max. 200 caractères)
                </label>
                <Input
                  id="description"
                  {...register('description')}
                  className="mt-1 h-12"
                  placeholder="Ex: Nous vous servons les meilleurs pizzas du coin !"
                />
                {errors.description && (
                  <p className="text-red-500 text-sm">
                    {errors.description.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="website"
                  className="block text-sm font-medium text-gray-700"
                >
                  Site Internet
                </label>
                <Input
                  id="website"
                  {...register('website')}
                  className="mt-1 h-12"
                  placeholder="Site web (optionnel)"
                />
                {errors.website && (
                  <p className="text-red-500 text-sm">
                    {errors.website.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="instagram"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lien Instagram
                </label>
                <Input
                  id="instagram"
                  {...register('instagram')}
                  className="mt-1 h-12"
                  placeholder="Lien Instagram (optionnel)"
                />
                {errors.instagram && (
                  <p className="text-red-500 text-sm">
                    {errors.instagram.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="facebook"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lien Facebook
                </label>
                <Input
                  id="facebook"
                  {...register('facebook')}
                  className="mt-1 h-12"
                  placeholder="Lien Facebook (optionnel)"
                />
                {errors.facebook && (
                  <p className="text-red-500 text-sm">
                    {errors.facebook.message}
                  </p>
                )}
              </div>

              <div className="block text-sm font-medium text-gray-700">
                <p>
                  Sur le profil de votre commerce, vous avez le choix d'afficher
                  les éléments suivants:
                </p>
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showPhone" className="flex-grow">
                  Afficher le numéro de téléphone
                </Label>
                <Switch
                  id="showPhone"
                  {...register('showPhone')}
                  className="scale-125"
                />
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showDescription" className="flex-grow">
                  Afficher la description
                </Label>
                <Switch
                  id="showDescription"
                  {...register('showDescription')}
                  className="scale-125"
                />
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showWebsite" className="flex-grow">
                  Afficher le site web
                </Label>
                <Switch
                  id="showWebsite"
                  {...register('showWebsite')}
                  className="scale-125"
                />
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showInstagram" className="flex-grow">
                  Afficher Instagram
                </Label>
                <Switch
                  id="showInstagram"
                  {...register('showInstagram')}
                  className="scale-125"
                />
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showFacebook" className="flex-grow">
                  Afficher Facebook
                </Label>
                <Switch
                  id="showFacebook"
                  {...register('showFacebook')}
                  className="scale-125"
                />
              </div>

              <Button
                type="submit"
                className="w-full mt-6 h-12"
                disabled={isUpdating}
              >
                {isUpdating ? (
                  <>Création{dots}</>
                ) : (
                  'Créer le profil commerçant'
                )}
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default NewMerchantPage;
