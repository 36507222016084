// src/utils/analyticsUtils.ts
import {
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  Firestore,
  orderBy,
  startAt,
  endAt,
  where,
} from "firebase/firestore";
import { startOfDay, subDays, format } from 'date-fns';

const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 jours en millisecondes

function formatStatsData(data: Record<string, any>): Record<string, any> {
  return Object.entries(data).reduce((acc, [key, value]) => {
    acc[key] = {
      totalClients: Number(value.totalClients) || 0,
      newSignUps: Number(value.newSignUps) || 0,
      pointsAdded: Number(value.pointsAdded) || 0,
      redemptions: Number(value.redemptions) || 0,
    };
    return acc;
  }, {} as Record<string, any>);
}

function getCachedData(key: string): Record<string, any> | null {
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    try {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    } catch (error) {
      console.error("Erreur lors du parsing des données en cache:", error);
    }
  }
  return null;
}

function setCachedData(key: string, data: Record<string, any>): void {
  localStorage.setItem(key, JSON.stringify({ data, timestamp: Date.now() }));
}

export async function fetchDailyStats(
  db: Firestore,
  shopId: string
): Promise<Record<string, any>> {
  const cacheKey = `dailyStats_${shopId}`;
  const cachedData = getCachedData(cacheKey);

  const today = startOfDay(new Date());
  const formattedToday = format(today, 'yyyy-MM-dd');

  const dailyStatsRef = collection(db, "analytics", shopId, "dailyStats");

  // Always fetch today's data from Firestore
  const todayQuery = query(
    dailyStatsRef,
    where("__name__", "==", formattedToday)
  );
  const todaySnapshot = await getDocs(todayQuery);
  const todayData: Record<string, any> = {};
  todaySnapshot.forEach((doc) => {
    todayData[doc.id] = doc.data();
  });

  let mergedData: Record<string, any>;

  if (cachedData) {
    // Merge cached data with today's data
    mergedData = { ...cachedData, ...todayData };
  } else {
    // If no cache, fetch data for the last 31 days including today
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];

    const fullQuery = query(
      dailyStatsRef,
      orderBy("__name__"),
      startAt(formattedThirtyDaysAgo),
      endAt(formattedToday)
    );

    const fullSnapshot = await getDocs(fullQuery);
    mergedData = {};

    // Fill with actual data if it exists
    fullSnapshot.forEach((doc) => {
      mergedData[doc.id] = doc.data();
    });
  }

  // Ensure all dates for the last 31 days are present, including today
  for (let i = 0; i < 31; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const dateKey = date.toISOString().split("T")[0];
    if (!mergedData[dateKey]) {
      mergedData[dateKey] = {
        totalClients: 0,
        newSignUps: 0,
        pointsAdded: 0,
        redemptions: 0,
      };
    }
  }

  // Ensure today's data is included and up-to-date
  mergedData[formattedToday] = {
    ...mergedData[formattedToday],
    ...todayData[formattedToday],
  };

  const formattedData = formatStatsData(mergedData);
  //console.log("Fetched daily stats:", formattedData);
  setCachedData(cacheKey, formattedData);
  return formattedData;
}

export async function fetchMonthlyStats(
  db: Firestore,
  shopId: string
): Promise<Record<string, any>> {
  const cacheKey = `monthlyStats_${shopId}`;
  const cachedData = getCachedData(cacheKey);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentMonthKey = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}`;

  const monthlyStatsRef = collection(db, "analytics", shopId, "monthlyStats");

  // Toujours lire les données du mois en cours depuis Firestore
  const currentMonthDoc = await getDoc(doc(monthlyStatsRef, currentMonthKey));
  const currentMonthData = currentMonthDoc.exists()
    ? { [currentMonthKey]: currentMonthDoc.data() }
    : {};

  if (cachedData) {
    // Fusionner les données en cache avec les données du mois en cours
    const mergedData = { ...cachedData, ...currentMonthData };
    setCachedData(cacheKey, mergedData);
    return mergedData;
  } else {
    // Si pas de cache, récupérer toutes les données des 24 derniers mois
    const startDate = new Date(currentDate);
    startDate.setMonth(startDate.getMonth() - 23);
    const formattedStartDate = startDate
      .toISOString()
      .split("T")[0]
      .slice(0, 7);

    const fullQuery = query(
      monthlyStatsRef,
      orderBy("__name__"),
      startAt(formattedStartDate),
      endAt(currentMonthKey)
    );

    const fullSnapshot = await getDocs(fullQuery);
    const fullData: Record<string, any> = {};

    // Initialiser tous les mois avec des valeurs à 0
    for (let i = 0; i < 24; i++) {
      const date = new Date(currentDate);
      date.setMonth(date.getMonth() - i);
      const monthKey = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}`;
      fullData[monthKey] = {
        totalClients: 0,
        newSignUps: 0,
        pointsAdded: 0,
        redemptions: 0,
      };
    }

    // Remplir avec les données réelles si elles existent
    fullSnapshot.forEach((doc) => {
      fullData[doc.id] = {
        ...fullData[doc.id],
        ...doc.data(),
      };
    });

    const formattedData = formatStatsData(fullData);
    //console.log("Fetched monthly stats:", formattedData);
    setCachedData(cacheKey, formattedData);
    return formattedData;
  }
}

export async function fetchYearlyStats(
  db: Firestore,
  shopId: string
): Promise<Record<string, any>> {
  const cacheKey = `yearlyStats_${shopId}`;
  const cachedData = getCachedData(cacheKey);

  const currentYear = new Date().getFullYear().toString();
  const yearlyStatsRef = collection(db, "analytics", shopId, "yearlyStats");

  // Toujours lire les données de l'année en cours depuis Firestore
  const currentYearDoc = await getDoc(doc(yearlyStatsRef, currentYear));
  const currentYearData = currentYearDoc.exists()
    ? { [currentYear]: currentYearDoc.data() }
    : {};

  if (cachedData) {
    // Fusionner les données en cache avec les données de l'année en cours
    const mergedData = { ...cachedData, ...currentYearData };
    setCachedData(cacheKey, mergedData);
    return mergedData;
  } else {
    // Si pas de cache, récupérer toutes les données annuelles
    const fullSnapshot = await getDocs(yearlyStatsRef);
    const fullData: Record<string, any> = {};

    // Initialiser les 5 dernières années avec des valeurs à 0
    const startYear = new Date().getFullYear() - 4;
    for (let year = startYear; year <= new Date().getFullYear(); year++) {
      fullData[year.toString()] = {
        totalClients: 0,
        newSignUps: 0,
        pointsAdded: 0,
        redemptions: 0,
      };
    }

    // Remplir avec les données réelles si elles existent
    fullSnapshot.forEach((doc) => {
      fullData[doc.id] = {
        ...fullData[doc.id],
        ...doc.data(),
      };
    });

    const formattedData = formatStatsData(fullData);
    //console.log("Fetched yearly stats:", formattedData);
    setCachedData(cacheKey, formattedData);
    return formattedData;
  }
}

export async function fetchAllTimeStats(
  db: Firestore,
  shopId: string
): Promise<Record<string, any>> {
  const allTimeStatsRef = doc(db, "analytics", shopId, "allTimeStats", "stats");
  const allTimeDoc = await getDoc(allTimeStatsRef);

  const data = allTimeDoc.exists() ? allTimeDoc.data() : {
    totalClients: 0,
    newSignUps: 0,
    pointsAdded: 0,
    redemptions: 0,
  };

  return formatStatsData({ "all-time": data });
}