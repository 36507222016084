import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithCredential,
  sendEmailVerification,
  OAuthProvider,
  signInWithPopup,
  User,
} from "firebase/auth";
import { app, auth } from "../firebase";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../@/components/ui/card";
import { z } from "zod";
import Spinner from "../@/components/ui/spinner";
import { FcGoogle } from "react-icons/fc";
import { ReactComponent as FideliPassLogo } from "../assets/FideliPass nom.svg";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { sha256 } from "crypto-hash";
import { Turnstile } from "@marsidev/react-turnstile";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { Purchases as PurchasesWeb } from "@revenuecat/purchases-js";
import { nanoid } from "nanoid";

interface VerifyTurnstileResult {
  success: boolean;
}

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const isEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === "true";
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const turnstileRef = useRef<any>(null);
  const navigate = useNavigate();
  const [loadingDots, setLoadingDots] = useState("");

  const handleTurnstileSuccess = (token: string) => {
    setTurnstileToken(token);
  };

  const handleTurnstileError = () => {
    console.error("Erreur Turnstile");
    setError(
      "Une erreur est survenue lors de la vérification. Veuillez réessayer."
    );
  };

  const handleTurnstileExpire = () => {
    setTurnstileToken(null);
    turnstileRef.current?.reset();
  };

  const emailSchema = z
    .string()
    .email("Adresse e-mail invalide")
    .max(254, "L'adresse e-mail ne doit pas dépasser 254 caractères")
    .regex(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Format d'adresse e-mail invalide"
    )
    .refine(
      (email) => {
        const domain = email.split("@")[1];
        return !["tempmail.com", "throwawaymail.com", "yopmail.com"].includes(
          domain
        );
      },
      { message: "Les adresses e-mail temporaires ne sont pas autorisées" }
    );

  const passwordSchema = z
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères")
    .regex(
      /(?=.*[a-z])/,
      "Le mot de passe doit contenir au moins une lettre minuscule"
    )
    .regex(
      /(?=.*[A-Z])/,
      "Le mot de passe doit contenir au moins une lettre majuscule"
    )
    .regex(/(?=.*\d)/, "Le mot de passe doit contenir au moins un chiffre")
    .regex(
      /(?=.*[@$!%*?&_-])/,
      "Le mot de passe doit contenir au moins un caractère spécial parmi : @, $, !, %, *, ?, &, _, -"
    );

  const handleAuth = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isNative = Capacitor.isNativePlatform();

    if (!isNative && !turnstileToken) {
      setError("Veuillez compléter le captcha Turnstile.");
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      // Envoyez le token au serveur pour validation
      if (!isNative) {
        const functions = getFunctions(app, "europe-west6");
        const verifyTurnstile = httpsCallable<
          { token: string },
          VerifyTurnstileResult
        >(functions, "verifyTurnstile");
        const result = await verifyTurnstile({ token: turnstileToken! });
        if (!result.data.success) {
          throw new Error("Échec de la vérification Turnstile");
        }
      }

      emailSchema.parse(email);

      if (isLogin) {
        passwordSchema.parse(password);
      } else {
        passwordConfirmSchema.parse({ password, confirmPassword });
      }

      let user;

      if (isLogin) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        user = userCredential.user;

        if (!user.emailVerified) {
          setError(
            "Veuillez vérifier votre adresse e-mail avant de vous connecter."
          );
          await sendEmailVerification(user);
          setIsLoading(false);
          return;
        }
      } else {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        user = userCredential.user;

        await setDoc(doc(db, "users", user.uid), {
          onboardingCompleted: false,
          qrCode: user.uid,
          countryId: "",
        });

        const redirectUrl = isNative
          ? "https://carty-d64ce.firebaseapp.com/"
          : "https://app.fidelipassapp.com/";

        const actionCodeSettings = {
          url: redirectUrl,
          handleCodeInApp: true,
        };

        if (isEmulator) {
          // eslint-disable-next-line no-console
          console.log(
            "Mode émulateur : simulation d'envoi d'e-mail de vérification"
          );
          setVerificationEmailSent(true);
        } else {
          await sendEmailVerification(user, actionCodeSettings);
          setVerificationEmailSent(true);
        }

        alert(
          "Un e-mail de vérification a été envoyé. Veuillez vérifier votre boîte de réception."
        );
      }

      // À ce stade, l'authentification a réussi
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      await handleLogin(user);

      if (!userDocSnap.exists() || !userDocSnap.data().onboardingCompleted) {
        navigate("/onboarding");
      } else {
        window.location.href = "/";
      }
    } catch (error: any) {
      console.error("Erreur d'authentification:", error);

      if (error instanceof z.ZodError) {
        setError(error.errors[0].message);
      } else if (
        error.code === "auth/wrong-password" ||
        error.code === "auth/user-not-found"
      ) {
        setError("Email ou mot de passe incorrect.");
      } else if (error.code === "auth/email-already-in-use") {
        setError("Cette adresse e-mail est déjà utilisée.");
      } else {
        setError(
          "Une erreur est survenue lors de l'authentification. Veuillez réessayer."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (user: User) => {
    try {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      let revenueCatUserId = userDoc.data()?.revenueCatUserId;

      if (!revenueCatUserId) {
        revenueCatUserId = nanoid();
        await updateDoc(doc(db, "users", user.uid), { revenueCatUserId });
      }

      if (Capacitor.isNativePlatform()) {
        const { customerInfo } = await Purchases.getCustomerInfo();
        if (customerInfo.originalAppUserId !== revenueCatUserId) {
          const { customerInfo: updatedInfo } = await Purchases.logIn({
            appUserID: revenueCatUserId,
          });
          return updatedInfo;
        } else {
          return customerInfo;
        }
      } else {
        // Web version
        const apiKey = process.env.REACT_APP_REVENUECAT_API_KEY_WEB || "";
        PurchasesWeb.configure(apiKey, revenueCatUserId);
        const purchasesInstance = PurchasesWeb.getSharedInstance();
        const customerInfo = await purchasesInstance.getCustomerInfo();
        return customerInfo;
      }
    } catch (error) {
      console.error("Error logging in to RevenueCat:", error);
      throw error;
    }
  };

  const resendVerificationEmail = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const redirectUrl = isNative
          ? "https://carty-d64ce.firebaseapp.com/"
          : "https://app.fidelipassapp.com/";

        const actionCodeSettings = {
          url: redirectUrl,
          handleCodeInApp: true,
        };

        if (isEmulator) {
          // eslint-disable-next-line no-console
          console.log(
            "Mode émulateur : simulation de renvoi d'e-mail de vérification"
          );
        } else {
          await sendEmailVerification(user, actionCodeSettings);
        }
        setVerificationEmailSent(true);
        alert("Un nouvel e-mail de vérification a été envoyé.");
      } catch (error) {
        console.error(
          "Erreur lors de l'envoi de l'e-mail de vérification:",
          error
        );
        setError("Erreur lors de l'envoi de l'e-mail de vérification.");
      }
    }
  };

  const handleAppleSignIn = async () => {
    if (Capacitor.getPlatform() !== "ios") {
      //console.log("Apple Sign In is only available on iOS");
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      // Generate a nonce and hash it
      const nonce = "random_nonce"; // Generate a random nonce here
      const hashedNonce = await sha256(nonce); // Hash the nonce

      const result = await SignInWithApple.authorize({
        clientId: "com.fidelipass.app",
        redirectURI: "https://carty-d64ce.firebaseapp.com/__/auth/handler",
        scopes: "email name",
        nonce: hashedNonce, // Pass the hashed nonce here
      });

      if (result.response && result.response.identityToken) {
        const provider = new OAuthProvider("apple.com");
        const credential = provider.credential({
          idToken: result.response.identityToken,
          rawNonce: nonce, // Use the original nonce here
        });

        const userCredential = await signInWithCredential(auth, credential);
        //console.log("User:", userCredential.user);

        await handleLogin(userCredential.user);

        // Check if onboarding has been completed
        const userDocRef = doc(db, "users", userCredential.user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          // Create a new user document if it's the first sign-in
          await setDoc(userDocRef, {
            onboardingCompleted: false,
            qrCode: userCredential.user.uid,
            countryId: "",
          });

          navigate("/onboarding");
        } else if (!userDocSnap.data().onboardingCompleted) {
          navigate("/onboarding");
        } else {
          window.location.href = "/";
        }
      } else {
        throw new Error("Failed to get identity token from Apple Sign In");
      }
    } catch (error: any) {
      console.error("Apple Sign In Error:", error);
      setError(
        error.message ||
          "Une erreur est survenue lors de l'authentification avec Apple."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setError(null);
    setIsLoading(true);

    try {
      if (Capacitor.getPlatform() === 'android') {
        // Initialiser Google Sign-In
        await GoogleAuth.initialize({
          clientId:
            Capacitor.getPlatform() === "android"
              ? "893135045307-395hlakcegv67r6u0v4aejae5vkakv8i.apps.googleusercontent.com"
              : "893135045307-8gpq22l704i2v6uvvji2au0tk8h1lndi.apps.googleusercontent.com",
          scopes: ["profile", "email"],
          grantOfflineAccess: true,
        });

        // Effectuer la connexion
        const user = await GoogleAuth.signIn();

        if (user) {
          // Créer les credentials Firebase à partir du token ID
          const credential = GoogleAuthProvider.credential(
            user.authentication.idToken
          );

          // Connecter l'utilisateur à Firebase
          const userCredential = await signInWithCredential(auth, credential);
          //console.log("User:", userCredential.user);

          await handleLogin(userCredential.user);

          // Vérifier si l'onboarding a été complété
          const userDocRef = doc(db, "users", userCredential.user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (!userDocSnap.exists()) {
            // Créer un nouveau document utilisateur si c'est la première connexion
            await setDoc(userDocRef, {
              onboardingCompleted: false,
              qrCode: userCredential.user.uid,
              countryId: "",
            });

            navigate("/onboarding");
          } else if (!userDocSnap.data().onboardingCompleted) {
            navigate("/onboarding");
          } else {
            window.location.href = "/";
          }
        }
      } else if (Capacitor.getPlatform() === 'web') {
        // Utilisez signInWithPopup pour le web
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        //console.log("User:", user);

        // Vérifier si l'onboarding a été complété
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          // Créer un nouveau document utilisateur si c'est la première connexion
          await setDoc(userDocRef, {
            onboardingCompleted: false,
            qrCode: user.uid,
            countryId: "",
          });

          navigate("/onboarding");
        } else if (!userDocSnap.data().onboardingCompleted) {
          navigate("/onboarding");
        } else {
          window.location.href = "/";
        }
      }
    } catch (error: any) {
      console.error("Erreur d'authentification Google:", error);
      setError(
        error.message ||
          "Une erreur est survenue lors de l'authentification Google."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleDeepLink = async (event: URLOpenListenerEvent) => {
      if (event.url.includes("oauth2redirect")) {
        await Browser.close();
        handleAuthResult(event.url);
      }
    };

    App.addListener("appUrlOpen", handleDeepLink);

    return () => {
      App.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const handleDeepLink = async (event: URLOpenListenerEvent) => {
        if (event.url.includes("oauth2redirect")) {
          await Browser.close();
          handleAuthResult(event.url);
        }
      };

      App.addListener("appUrlOpen", handleDeepLink);

      return () => {
        App.removeAllListeners();
      };
    }
  }, []);

  const handleAuthResult = async (url: string) => {
    const params = new URLSearchParams(url.split("#")[1]);
    const accessToken = params.get("access_token");

    if (accessToken) {
      try {
        const credential = GoogleAuthProvider.credential(null, accessToken);
        const result = await signInWithCredential(auth, credential);
        //console.log("User:", result.user);
        window.location.href = "/";
      } catch (error: any) {
        console.error(
          "Erreur lors de l'authentification avec le token:",
          error
        );
        setError(
          error.message ||
            "Une erreur est survenue lors de l'authentification Google."
        );
      }
    } else {
      setError("L'authentification Google a échoué ou a été annulée.");
    }
  };

  useEffect(() => {
    const checkRedirectResult = async () => {
      if (!Capacitor.isNativePlatform()) {
        try {
          const result = await getRedirectResult(auth);
          if (result) {
            const user = result.user;
            //console.log("User:", user);
            window.location.href = "/";
          }
        } catch (error: any) {
          console.error("Erreur d'authentification Google:", error);
          setError(
            error.message ||
              "Une erreur est survenue lors de l'authentification Google."
          );
        }
      }
    };

    checkRedirectResult();
  }, []);

  useLayoutEffect(() => {
    const originalOverflow = document.body.style.overflow;
    const originalHeight = document.body.style.height;
    const originalPosition = document.body.style.position;

    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    document.body.style.position = "fixed";

    const handleTouchMove = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const handleTouchStart = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchstart", handleTouchStart, {
      passive: false,
    });

    return () => {
      document.body.style.overflow = originalOverflow;
      document.body.style.height = originalHeight;
      document.body.style.position = originalPosition;
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  const passwordConfirmSchema = z
    .object({
      password: passwordSchema,
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Les mots de passe ne correspondent pas",
      path: ["confirmPassword"],
    });

  const isAndroidOrWeb =
    Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "web";

  const openLink = async (url: string) => {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url });
    } else {
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    if (!turnstileToken) {
      const interval = setInterval(() => {
        setLoadingDots((dots) => (dots.length >= 3 ? "" : dots + "."));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [turnstileToken]);

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <FideliPassLogo className="h-5 w-auto" />
          <div className="w-12 h-12"></div>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="flex items-start justify-center min-h-full p-4 pt-8 sm:pt-16">
          <div className="w-full max-w-md">
            <Card className="border-none shadow-none bg-transparent">
              <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0">
                <CardTitle className="text-2xl font-bold">
                  {isLogin ? "Connexion" : "Inscription"}
                </CardTitle>
                <Button
                  variant="link"
                  onClick={() => setIsLogin(!isLogin)}
                  size={isNative ? "mobile" : "default"}
                  className="p-0 h-auto"
                >
                  {isLogin ? "Pas encore de compte?" : "Déjà un compte ?"}
                </Button>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleAuth} className="space-y-4">
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    className="w-full h-12"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    isMobile={isNative}
                  />
                  <Input
                    type="password"
                    placeholder="Mot de passe"
                    value={password}
                    className="w-full h-12"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    isMobile={isNative}
                  />
                  {!isLogin && (
                    <Input
                      type="password"
                      placeholder="Confirmer le mot de passe"
                      className="w-full h-12"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      isMobile={isNative}
                    />
                  )}
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                  {!isNative && (
                    <Turnstile
                      ref={turnstileRef}
                      siteKey="0x4AAAAAAAjomArncZuClenq"
                      onSuccess={handleTurnstileSuccess}
                      onError={handleTurnstileError}
                      onExpire={handleTurnstileExpire}
                      options={{
                        theme: "light",
                        size: "invisible",
                      }}
                    />
                  )}
                  <Button
                    type="submit"
                    className="w-full h-12"
                    disabled={isLoading || (!isNative && !turnstileToken)}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : !isNative && !turnstileToken ? (
                      <span>Vérification{loadingDots}</span>
                    ) : isLogin ? (
                      "Se connecter"
                    ) : (
                      "S'inscrire"
                    )}
                  </Button>
                </form>
                {!isLogin && verificationEmailSent && (
                  <Button
                    onClick={resendVerificationEmail}
                    className="w-full mt-4 h-12"
                    size={isNative ? "mobile" : "default"}
                  >
                    Renvoyer l'e-mail de vérification
                  </Button>
                )}
                {isAndroidOrWeb && (
                  <Button
                    onClick={handleGoogleSignIn}
                    className="w-full mt-4 flex items-center justify-center bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 h-12"
                    disabled={isLoading}
                    size={isNative ? "mobile" : "default"}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        <FcGoogle className="w-5 h-5 mr-2" />
                        <span>Se connecter avec Google</span>
                      </>
                    )}
                  </Button>
                )}
                {Capacitor.getPlatform() === "ios" && (
                  <Button
                    onClick={handleAppleSignIn}
                    className="w-full mt-4 flex items-center justify-center bg-black text-white border border-black hover:bg-gray-900 h-12"
                    disabled={isLoading}
                    size={isNative ? "mobile" : "default"}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"
                          />
                        </svg>
                        <span>Se connecter avec Apple</span>
                      </>
                    )}
                  </Button>
                )}
                <p className="mt-4 text-sm text-center text-gray-600">
                  En vous connectant, vous acceptez nos{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() =>
                      openLink(
                        "https://fidelipassapp.com/app-terms-of-services"
                      )
                    }
                  >
                    Conditions d'utilisation
                  </span>{" "}
                  et notre{" "}
                  <span
                    className="underline cursor-pointer"
                    onClick={() =>
                      openLink("https://fidelipassapp.com/app-privacy-policy")
                    }
                  >
                    Politique de confidentialité
                  </span>
                  .
                </p>
                {!isNative && (
                  <p className="text-xs text-center text-gray-500 mt-2">
                    Cette page est protégée par Cloudflare Turnstile.
                  </p>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
