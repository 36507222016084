// Ajouter ces imports
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { Shop } from '../App';
import { auth, db } from 'src/firebase';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { checkRevenueCatEntitlements } from '../utils/revenueCatUtils';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';

export interface MerchantShop {
  id: string;
  name: string;
  shopId: string;
  userId: string;
  [key: string]: any;
}

export interface CachedShop {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  canton?: string;
  country: string;
  offre: string;
  category: string;
  latitude: number;
  longitude: number;
  description?: string;
  phone?: string;
  website?: string;
  instagram?: string;
  facebook?: string;
  id: string;
}

export interface UserLocation {
  latitude: number;
  longitude: number;
}

export interface UserLocationError {
  code: 'PERMISSION_DENIED' | 'POSITION_UNAVAILABLE' | 'TIMEOUT' | 'UNKNOWN';
  message: string;
}

export const USER_COUNTRY_CACHE_KEY = 'userCountry';

// Ajouter cette fonction
export const fetchUserShops = async (userId: string): Promise<Shop[]> => {
  if (!userId) {
    throw new Error('User ID is required');
  }

  const walletRef = collection(db, 'wallets', userId, 'wallet');
  const querySnapshot = await getDocs(walletRef);
  const shops: Shop[] = [];

  querySnapshot.forEach(doc => {
    shops.push({ id: doc.id, ...doc.data() } as Shop);
  });

  return shops;
};

export function useUserCountry() {
  return useQuery({
    queryKey: ['user-country'],
    queryFn: async () => {
      const user = auth.currentUser;
      if (!user) return '';

      // Vérifier d'abord le cache
      const cachedCountry = localStorage.getItem(USER_COUNTRY_CACHE_KEY);
      if (cachedCountry) {
        return cachedCountry;
      }

      // Si pas dans le cache, lire depuis Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const countryId = userData.countryId || '';
        // Mettre en cache
        localStorage.setItem(USER_COUNTRY_CACHE_KEY, countryId);
        return countryId;
      }
      return '';
    },
    staleTime: 7 * 24 * 60 * 60 * 1000, // 7 jours
    gcTime: 7 * 24 * 60 * 60 * 1000, // 7 jours
  });
}

export function useEntitlementsQuery() {
  return useQuery({
    queryKey: ['entitlements'],
    queryFn: checkRevenueCatEntitlements,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
}

export function useMerchantProfile() {
  return useQuery({
    queryKey: ['merchantProfile', auth.currentUser?.uid],
    queryFn: async () => {
      const user = auth.currentUser;
      if (!user) return false;

      const q = query(
        collection(db, 'merchants'),
        where('userId', '==', user.uid)
      );
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    },
    enabled: !!auth.currentUser,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
}

export const fetchMerchantShops = async (
  userId: string | undefined
): Promise<MerchantShop[]> => {
  if (!userId) {
    throw new Error('User ID is required');
  }
  const q = query(collection(db, 'merchants'), where('userId', '==', userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    shopId: doc.data().shopId,
  })) as MerchantShop[];
};

export function useMerchantShops(): UseQueryResult<MerchantShop[], Error> {
  return useQuery({
    queryKey: ['merchantShops', auth.currentUser?.uid],
    queryFn: () => fetchMerchantShops(auth.currentUser?.uid),
    enabled: !!auth.currentUser,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // Les données sont conservées en cache pendant 30 minutes
  });
}

export function useSubscriptionCheck() {
  return useQuery({
    queryKey: ['subscription-check'],
    queryFn: checkRevenueCatEntitlements,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000,
    select: data => ({
      isActive: data.subscriptionStatus?.isActive ?? false,
      subscriptionStatus: data.subscriptionStatus,
    }),
  });
}

async function fetchShops(countryId: string): Promise<CachedShop[]> {
  try {
    const shopsRef = collection(db, 'globalShops', 'countries', countryId);
    const shopsSnapshot = await getDocs(shopsRef);

    return shopsSnapshot.docs.map(doc => {
      const data = doc.data();
      const shop: CachedShop = {
        name: data.name,
        address: data.address,
        postalCode: data.postalCode,
        city: data.city,
        country: data.country,
        offre: data.offre,
        category: data.category,
        latitude: data.latitude,
        longitude: data.longitude,
        id: data.id,
      };

      if (data.canton) shop.canton = data.canton;
      if (data.showDescription && data.description)
        shop.description = data.description;
      if (data.showPhone && data.phone) shop.phone = data.phone;
      if (data.showWebsite && data.website) shop.website = data.website;
      if (data.showInstagram && data.instagram) shop.instagram = data.instagram;
      if (data.showFacebook && data.facebook) shop.facebook = data.facebook;

      return shop;
    });
  } catch (error) {
    console.error('Erreur lors de la récupération des magasins:', error);
    throw error;
  }
}

export function useGlobalShops(countryId: string | null) {
  return useQuery({
    queryKey: ['globalShops', countryId],
    queryFn: async () => {
      const cachedShops = await fetchShops(countryId || '');
      // Conversion de CachedShop[] en Shop[]
      return cachedShops.map(shop => ({
        ...shop,
        added: false,
        progression: 0,
        redemptionCount: 0,
        bubbleCount: 10, // Valeur par défaut
        userId: '', // Valeur par défaut
        showDescription: !!shop.description,
        showPhone: !!shop.phone,
        showWebsite: !!shop.website,
        showInstagram: !!shop.instagram,
        showFacebook: !!shop.facebook,
      }));
    },
    enabled: !!countryId,
    staleTime: 12 * 60 * 60 * 1000, // 12 heures
    gcTime: 24 * 60 * 60 * 1000, // 24 heures
  });
}

export function useUserLocation() {
  return useQuery<UserLocation, UserLocationError>({
    queryKey: ['userLocation'],
    queryFn: async () => {
      try {
        let position;
        if (Capacitor.isNativePlatform()) {
          try {
            const coordinates = await Geolocation.getCurrentPosition({
              enableHighAccuracy: true,
              timeout: 5000,
            });
            position = {
              latitude: coordinates.coords.latitude,
              longitude: coordinates.coords.longitude,
            };
            return position;
          } catch (error: any) {
            // Gestion des erreurs spécifiques à Capacitor
            if (error.message.includes('denied')) {
              throw {
                code: 'PERMISSION_DENIED',
                message:
                  "L'accès à la géolocalisation a été refusé. Veuillez vérifier vos paramètres de confidentialité.",
              };
            } else if (error.message.includes('unavailable')) {
              throw {
                code: 'POSITION_UNAVAILABLE',
                message:
                  'Les informations de géolocalisation sont indisponibles. Veuillez réessayer plus tard.',
              };
            } else if (error.message.includes('timeout')) {
              throw {
                code: 'TIMEOUT',
                message:
                  'La demande de géolocalisation a expiré. Veuillez vérifier votre connexion et réessayer.',
              };
            }
            throw {
              code: 'UNKNOWN',
              message:
                "Une erreur s'est produite lors de la récupération de la position.",
            };
          }
        } else {
          if (!navigator.geolocation) {
            throw {
              code: 'POSITION_UNAVAILABLE',
              message:
                "La géolocalisation n'est pas supportée par ce navigateur.",
            };
          }

          try {
            const geolocation = await new Promise<GeolocationPosition>(
              (resolve, reject) =>
                navigator.geolocation.getCurrentPosition(resolve, reject, {
                  enableHighAccuracy: true,
                  timeout: 5000,
                  maximumAge: 0,
                })
            );

            return {
              latitude: geolocation.coords.latitude,
              longitude: geolocation.coords.longitude,
            };
          } catch (error) {
            if (error instanceof GeolocationPositionError) {
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  throw {
                    code: 'PERMISSION_DENIED',
                    message:
                      "L'accès à la géolocalisation a été refusé. Veuillez vérifier vos paramètres de confidentialité.",
                  };
                case error.POSITION_UNAVAILABLE:
                  throw {
                    code: 'POSITION_UNAVAILABLE',
                    message:
                      'Les informations de géolocalisation sont indisponibles. Veuillez réessayer plus tard.',
                  };
                case error.TIMEOUT:
                  throw {
                    code: 'TIMEOUT',
                    message:
                      'La demande de géolocalisation a expiré. Veuillez vérifier votre connexion et réessayer.',
                  };
              }
            }
            throw {
              code: 'UNKNOWN',
              message:
                "Une erreur s'est produite lors de la récupération de la position.",
            };
          }
        }
      } catch (error: any) {
        console.error('Erreur de géolocalisation:', error);
        throw error;
      }
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // 30 minutes
    retry: 1,
  });
}
