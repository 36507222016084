import React from 'react';
import { Button } from '../@/components/ui/button';
import {
  UserCircle,
  MessageCircle,
  Store,
  BarChart2,
  CreditCard,
  InfoIcon,
  RefreshCcw,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../@/components/ui/card';
import { Capacitor } from '@capacitor/core';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { Header } from 'src/components/Header';
import { useQueryClient } from '@tanstack/react-query';
import { useEntitlementsQuery, useMerchantProfile } from '../hooks/queries';
import { Dialog } from '@capacitor/dialog';
import Spinner from 'src/@/components/ui/spinner';

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const isNative = Capacitor.isNativePlatform();
  const queryClient = useQueryClient();

  const { data: entitlementInfo, isLoading: isEntitlementLoading } =
    useEntitlementsQuery();
  const { data: hasMerchantProfile, isLoading: isMerchantLoading } =
    useMerchantProfile();

  const handleRestorePurchases = async () => {
    try {
      await Purchases.restorePurchases();
      await queryClient.invalidateQueries({ queryKey: ['entitlements'] });

      if (isNative) {
        await Dialog.alert({
          title: 'Succès',
          message: 'Vos achats ont été restaurés avec succès.',
          buttonTitle: 'OK',
        });
      } else {
        alert('Vos achats ont été restaurés avec succès.');
      }
    } catch (error) {
      console.error('Erreur lors de la restauration des achats :', error);
      if (isNative) {
        await Dialog.alert({
          title: 'Erreur',
          message:
            'Une erreur est survenue lors de la restauration de vos achats. Veuillez réessayer.',
          buttonTitle: 'OK',
        });
      } else {
        alert(
          'Une erreur est survenue lors de la restauration de vos achats. Veuillez réessayer.'
        );
      }
    }
  };

  if (isEntitlementLoading || isMerchantLoading) {
    return <Spinner />;
  }

  const hasActiveEntitlement = entitlementInfo?.hasActiveEntitlement ?? false;

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? 'safe-area-inset-top safe-area-inset-left safe-area-inset-right'
          : ''
      }`}
    >
      <Header variant="default" className="z-10" />
      <div className="flex-grow overflow-y-auto">
        <div className="p-4">
          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Paramètres</CardTitle>
            </CardHeader>
            <CardContent>
              <Button
                className="w-full mb-4 justify-start text-lg h-12 py-0"
                variant="ghost"
                onClick={() => navigate('/account')}
              >
                <UserCircle className="mr-2 h-6 w-6" /> Compte
              </Button>

              <a href="mailto:team@fidelipassapp.com">
                <Button
                  className="w-full mb-4 justify-start text-lg h-12 py-0"
                  variant="ghost"
                >
                  <MessageCircle className="mr-2 h-6 w-6" /> Contactez-nous
                </Button>
              </a>

              <Button
                className="w-full justify-start text-lg mb-4 h-12 py-0"
                variant="ghost"
                onClick={() =>
                  navigate(
                    hasActiveEntitlement
                      ? '/merchant-profiles'
                      : '/change-subscription'
                  )
                }
              >
                <Store className="mr-2 h-6 w-6" />
                {hasActiveEntitlement || hasMerchantProfile
                  ? 'Mon commerce'
                  : 'Créer mon commerce'}
              </Button>

              {hasActiveEntitlement && (
                <Button
                  className="w-full mb-4 justify-start text-lg h-12 py-0"
                  variant="ghost"
                  onClick={() => navigate('/manage-subscription')}
                >
                  <CreditCard className="mr-2 h-6 w-6" />
                  Gérer mon abonnement
                </Button>
              )}

              {!hasActiveEntitlement && hasMerchantProfile && (
                <Button
                  className="w-full mb-4 justify-start text-lg h-12 py-0"
                  variant="ghost"
                  onClick={() => navigate('/change-subscription')}
                >
                  <CreditCard className="mr-2 h-6 w-6" />
                  Renouveler mon abonnement
                </Button>
              )}

              {isNative && (
                <Button
                  className="w-full mb-4 justify-start text-lg h-12 py-0"
                  variant="ghost"
                  onClick={handleRestorePurchases}
                >
                  <RefreshCcw className="mr-2 h-6 w-6" />
                  Restaurer les achats
                </Button>
              )}

              {hasMerchantProfile && (
                <Button
                  className="w-full mb-4 justify-start text-lg h-12 py-0"
                  variant="ghost"
                  onClick={() => navigate('/analytics')}
                >
                  <BarChart2 className="mr-2 h-6 w-6" /> Analyses
                </Button>
              )}

              <Button
                className="w-full mb-4 justify-start text-lg h-12 py-0"
                variant="ghost"
                onClick={() => navigate('/about')}
              >
                <InfoIcon className="mr-2 h-6 w-6" /> À propos
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
