import React, { useState, useEffect, useRef } from 'react';
import { Phone, Globe, Instagram, Facebook, Search, Map } from 'lucide-react';
import { Button } from '../@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Shop } from '../App';
import { Card } from '../@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '../@/components/ui/dialog';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { getCountryCode, countryMap } from '../utils/countryUtils';
import { auth, db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import Fuse from 'fuse.js';
import { Header } from 'src/components/Header';
import { useQueryClient } from '@tanstack/react-query';
import { useUserCountry } from '../hooks/queries';

interface AddShopPageProps {
  availableShops: Shop[];
}

const USER_COUNTRY_CACHE_KEY = 'userCountry';

const AddShopPage: React.FC<AddShopPageProps> = ({ availableShops }) => {
  const navigate = useNavigate();
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isNative = Capacitor.isNativePlatform();
  const [filteredShops, setFilteredShops] = useState<Shop[]>(availableShops);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const fuseRef = useRef<Fuse<Shop> | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const queryClient = useQueryClient();
  const { data: userCountry = '' } = useUserCountry();

  useEffect(() => {
    fuseRef.current = new Fuse(availableShops, {
      keys: ['name', 'city', 'canton', 'category', 'postalCode'],
      threshold: 0.3,
    });
  }, [availableShops]);

  useEffect(() => {
    if (searchTerm && fuseRef.current) {
      const results = fuseRef.current.search(searchTerm);
      setFilteredShops(results.map(result => result.item));
      setSuggestions(
        results.map(result => {
          const shop = result.item;
          return `${shop.name} - ${shop.city}${
            shop.canton ? `, ${shop.canton}` : ''
          } (${shop.postalCode}) - ${shop.category}`;
        })
      );
    } else {
      setFilteredShops(availableShops);
      setSuggestions([]);
    }
  }, [searchTerm, availableShops]);

  const handleCountryChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCountry = event.target.value;
    const countryCode = getCountryCode(selectedCountry);

    // Mettre à jour le cache
    localStorage.setItem(USER_COUNTRY_CACHE_KEY, countryCode);

    const user = auth.currentUser;
    if (user) {
      await updateDoc(doc(db, 'users', user.uid), { countryId: countryCode });

      // Invalider les caches pertinents
      queryClient.invalidateQueries({ queryKey: ['user-country'] });

      // Recharger la page si nécessaire
      if (Capacitor.isNativePlatform()) {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    }
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 300);
    } else {
      setSearchTerm('');
      setSuggestions([]);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (searchTerm === '') {
        setIsSearchOpen(false);
      }
      setIsFocused(false);
    }, 200);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setIsSearchOpen(true);
  };

  const handleShopClick = (shop: Shop) => {
    setSelectedShop(shop);
    setIsDialogOpen(true);
  };

  const openLink = async (url: string | undefined) => {
    if (!url) return;

    if (Capacitor.isNativePlatform()) {
      // Sur mobile, utilisez le navigateur intégré de Capacitor
      await Browser.open({ url });
    } else {
      // Sur ordinateur, ouvrez dans une nouvelle fenêtre
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const openInMaps = (shop: Shop | null) => {
    if (!shop) return;

    const address = `${shop.address}, ${shop.postalCode} ${shop.city}, ${shop.country}`;
    const encodedAddress = encodeURIComponent(address);

    // Vérifier si l'utilisateur est sur un appareil iOS ou macOS
    const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(
      navigator.userAgent
    );

    if (isAppleDevice) {
      // Ouvrir dans Apple Maps
      window.location.href = `maps:0,0?q=${encodedAddress}`;
    } else {
      // Ouvrir dans Google Maps
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
        '_blank'
      );
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchTerm(suggestion.split(' - ')[0]);
    setIsSearchOpen(true);
    setSuggestions([]);
    const selectedShop = availableShops.find(
      shop =>
        `${shop.name} - ${shop.city}${shop.canton ? `, ${shop.canton}` : ''} (${
          shop.postalCode
        }) - ${shop.category}` === suggestion
    );
    if (selectedShop) {
      handleShopClick(selectedShop);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchInputRef.current?.blur();
    }
  };

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative ? 'native-platform' : ''
      }`}
    >
      <div
        className={`bg-white z-10 ${
          isNative
            ? 'safe-area-inset-top safe-area-inset-left safe-area-inset-right'
            : ''
        }`}
      >
        <Header
          variant="search"
          searchProps={{
            isSearchOpen,
            searchTerm,
            onSearchChange: setSearchTerm,
            onSearchToggle: toggleSearch,
            onSearchBlur: handleBlur,
            onSearchFocus: handleFocus,
            onKeyDown: handleKeyDown,
            searchInputRef,
          }}
          className="z-10"
        />
        {suggestions.length > 0 && isFocused && (
          <ul className="absolute z-50 w-[calc(100%-2rem)] bg-white border border-gray-300 mt-1 rounded-md shadow-lg mx-4">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
        <div className="px-4 pb-4">
          {userCountry !== '' && (
            <Button onClick={() => navigate('/map')} className="w-full h-12">
              <Map className="mr-2 h-5 w-5" />
              Découvrir sur le plan
            </Button>
          )}
        </div>
      </div>
      <div className={`flex-1 overflow-y-auto ${isNative ? 'pb-32' : 'pb-24'}`}>
        <div className="p-4">
          {userCountry === '' ? (
            <div className="text-center">
              <p className="mb-4 text-left text-gray-600">
                Choisissez un pays (accessible également dans les paramètres du
                compte)
              </p>
              <select
                value={userCountry}
                onChange={handleCountryChange}
                className="w-full h-12 bg-white text-gray-600 border border-gray-300 rounded-md px-3 py-2 mb-4"
              >
                <option value="">Sélectionnez un pays</option>
                {Object.keys(countryMap).map(country => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
          ) : availableShops.length === 0 ? (
            <div className="text-center">
              <p className="mb-4 text-left text-gray-600">
                Aucun nouveau commerce n'est disponible à ajouter.
              </p>
              <p className="mb-4 text-left text-gray-600">
                Vous ne voyez pas votre commerce préféré ? Faites-le nous savoir
                à l'aide du bouton ci-dessous.
              </p>
              <Button
                onClick={() =>
                  (window.location.href = 'mailto:team@fidelipassapp.com')
                }
                className="w-full h-12 bg-white text-gray-600 border border-gray-600 hover:bg-gray-200"
              >
                Recommander un commerce
              </Button>
            </div>
          ) : (
            <>
              {filteredShops.map((shop, index) => (
                <Card
                  key={`${shop.id}-${index}`}
                  className="mb-4 cursor-pointer h-24" // Hauteur fixe pour toutes les cartes
                  onClick={() => handleShopClick(shop)}
                >
                  <div className="p-4 flex items-center justify-between h-full">
                    <div className="flex flex-col overflow-hidden flex-grow mr-4">
                      <h3 className="text-lg font-semibold truncate">
                        {shop.name}
                      </h3>
                      <p className="text-sm text-gray-500 truncate">
                        {shop.city}
                      </p>
                    </div>
                    <div className="flex items-center flex-shrink-0">
                      <span className="text-xs text-gray-600 bg-gray-100 px-2 py-1 rounded whitespace-nowrap">
                        {shop.category}
                      </span>
                    </div>
                  </div>
                </Card>
              ))}
            </>
          )}
        </div>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px] rounded-lg">
          <DialogHeader>
            <DialogTitle className="text-left">
              {selectedShop?.name || 'Détails du magasin'}
            </DialogTitle>
            <DialogDescription className="text-left text-sm text-muted-foreground">
              {selectedShop?.address || 'Adresse non spécifiée'},{' '}
              {selectedShop?.postalCode || ''} {selectedShop?.city || ''}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4 text-left">
            {selectedShop?.showDescription && selectedShop?.description && (
              <p className="text-left">{selectedShop.description}</p>
            )}
            {selectedShop?.offre && (
              <p className="mt-2 font-semibold text-left">
                Offre à la complétion : {selectedShop.offre}
              </p>
            )}
          </div>
          <div className="flex justify-start space-x-4 mt-4">
            <button
              onClick={() => openInMaps(selectedShop)}
              className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
            >
              <Map className="w-6 h-6" />
            </button>
            {selectedShop?.showPhone && selectedShop?.phone && (
              <a
                href={`tel:${selectedShop.phone}`}
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Phone className="w-6 h-6" />
              </a>
            )}
            {selectedShop?.showWebsite && selectedShop?.website && (
              <button
                onClick={() => openLink(selectedShop.website)}
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Globe className="w-6 h-6" />
              </button>
            )}
            {selectedShop?.showInstagram && selectedShop?.instagram && (
              <a
                href={selectedShop.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Instagram className="w-6 h-6" />
              </a>
            )}
            {selectedShop?.showFacebook && selectedShop?.facebook && (
              <a
                href={selectedShop.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-primary-dark w-12 h-12 flex items-center justify-center"
              >
                <Facebook className="w-6 h-6" />
              </a>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddShopPage;
