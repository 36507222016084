import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../@/components/ui/card";
import { Button } from "../@/components/ui/button";
import { Users, TrendingUp, Repeat, Zap, ChevronLeft } from "lucide-react";
import { Capacitor } from "@capacitor/core";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LabelList,
} from "../@/components/ui/charts";
import Spinner from "../@/components/ui/spinner";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "src/firebase";
import {
  fetchDailyStats,
  fetchYearlyStats,
  fetchAllTimeStats,
  fetchMonthlyStats,
} from "../utils/analyticsUtils";
import useRevenueCatEntitlement from "../hooks/useRevenueCatEntitlement";

interface CategoryData {
  current: number;
  previous: number;
  growthRate: number;
  byPeriod: { label: string; value: number }[];
}

interface PeriodData {
  [key: string]: CategoryData;
}

interface AnalyticsData {
  daily: PeriodData;
  monthly: PeriodData;
  yearly: PeriodData;
  allTime: PeriodData;
}

const AnalyticsPage: React.FC<{ onExitComplete: () => void }> = ({
  onExitComplete,
}) => {
  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState<
    "daily" | "monthly" | "yearly" | "all-time"
  >("all-time");
  const [selectedCategory, setSelectedCategory] = useState<
    "totalClients" | "newSignUps" | "pointsAdded" | "redemptions"
  >("pointsAdded");
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isNative = Capacitor.isNativePlatform();
  const {
    hasActiveEntitlement,
    entitlements,
    isLoading: entitlementLoading,
  } = useRevenueCatEntitlement();
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    if (entitlementLoading) return;

    if (!hasActiveEntitlement) {
      navigate("/settings");
      return;
    }

    const fetchAnalytics = async () => {
      const user = auth.currentUser;
      if (!user) return;

      try {
        setIsLoading(true);

        const merchantDoc = await getDoc(doc(db, "merchants", user.uid));
        if (!merchantDoc.exists()) {
          console.error("Document marchand non trouvé");
          return;
        }
        const shopId = merchantDoc.data().shopId;

        if (!shopId) {
          console.error("shopId non trouvé dans le document marchand");
          return;
        }

        const formattedData: AnalyticsData = {
          daily: formatPeriodData(await fetchDailyStats(db, shopId), "daily"),
          monthly: formatPeriodData(
            await fetchMonthlyStats(db, shopId),
            "monthly"
          ),
          yearly: formatPeriodData(
            await fetchYearlyStats(db, shopId),
            "yearly"
          ),
          allTime: formatPeriodData(
            await fetchAllTimeStats(db, shopId),
            "all-time"
          ),
        };

        //console.log("Données formatées:", formattedData);

        setAnalytics(formattedData);
      } catch (error) {
        console.error("Erreur lors de la récupération des analyses:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnalytics();
  }, [hasActiveEntitlement, navigate, entitlementLoading]);

  const formatPeriodData = (
    data: Record<string, any>,
    period: "daily" | "monthly" | "yearly" | "all-time"
  ): PeriodData => {
    //console.log("Formatting period data:", data);

    const sortedData = Object.entries(data)
      .map(([label, value]) => ({
        label,
        ...value,
      }))
      .sort(
        (a, b) => new Date(a.label).getTime() - new Date(b.label).getTime()
      );

    const mostRecentPeriod = sortedData[sortedData.length - 1] || {};
    const previousPeriod = sortedData[sortedData.length - 2] || {};

    const calculateGrowthRate = (current: number, previous: number) => {
      if (previous === 0) return current > 0 ? 100 : 0;
      return ((current - previous) / previous) * 100;
    };

    const formatCategory = (category: string) => ({
      current: Number(mostRecentPeriod[category]) || 0,
      previous: Number(previousPeriod[category]) || 0,
      growthRate: calculateGrowthRate(
        Number(mostRecentPeriod[category]) || 0,
        Number(previousPeriod[category]) || 0
      ),
      byPeriod: sortedData.map((item) => ({
        label: item.label,
        value: Number(item[category]) || 0,
      })),
    });

    return {
      totalClients: formatCategory("totalClients"),
      newSignUps: formatCategory("newSignUps"),
      pointsAdded: formatCategory("pointsAdded"),
      redemptions: formatCategory("redemptions"),
    };
  };

  const formatChartDate = (
    label: string,
    period: "daily" | "monthly" | "yearly" | "all-time"
  ) => {
    if (period === "all-time") return "Total";
  
    const date = new Date(label);
    if (isNaN(date.getTime())) {
      console.warn(`Invalid date: ${label}`);
      return label;
    }
  
    switch (period) {
      case "daily":
        return `${String(date.getDate()).padStart(2, "0")}/${String(
          date.getMonth() + 1
        ).padStart(2, "0")}`;
      case "monthly":
        return `${String(date.getMonth() + 1).padStart(2, "0")}/${String(
          date.getFullYear()
        ).slice(2)}`;
      case "yearly":
        return String(date.getFullYear());
      default:
        return label;
    }
  };

  const StatCard: React.FC<{
    title: string;
    data: CategoryData;
    icon: React.ReactNode;
    category: "totalClients" | "newSignUps" | "pointsAdded" | "redemptions";
    onSelect: (
      category: "totalClients" | "newSignUps" | "pointsAdded" | "redemptions"
    ) => void;
    isSelected: boolean;
    period: "daily" | "monthly" | "yearly" | "all-time";
  }> = ({ title, data, icon, category, onSelect, isSelected, period }) => {
    const formatGrowthRate = (rate: number) => {
      if (isNaN(rate) || !isFinite(rate)) {
        return "";
      }
      return `${rate > 0 ? "+" : ""}${rate.toFixed(1)}%`;
    };

    return (
      <Card
        className={`mb-4 cursor-pointer transition-all duration-200 ease-in-out ${
          isSelected ? "ring-2 ring-primary ring-inset bg-accent" : ""
        }`}
        onClick={() => onSelect(category)}
      >
        <CardContent className="flex items-center p-4">
          <div className="mr-4 p-3 bg-primary/10 rounded-full">{icon}</div>
          <div className="flex-grow">
            <div className="flex justify-between items-center">
              <p className="text-sm text-muted-foreground">{title}</p>
              {period !== "all-time" && (
                <p
                  className={`text-sm ${
                    data.growthRate > 0
                      ? "text-emerald-600"
                      : data.growthRate < 0
                      ? "text-rose-600"
                      : "text-muted-foreground"
                  }`}
                >
                  {formatGrowthRate(data.growthRate)}
                </p>
              )}
            </div>
            <h3 className="text-2xl font-bold">
              {data.current.toLocaleString()}
            </h3>
          </div>
        </CardContent>
      </Card>
    );
  };

  if (isLoading || entitlementLoading || !analytics) {
    return <Spinner />;
  }

  const currentData =
    analytics[selectedPeriod === "all-time" ? "allTime" : selectedPeriod];

  //console.log("Current data for rendering:", currentData);

  const handleBackClick = () => {
    setIsExiting(true);
    setTimeout(() => {
      onExitComplete();
    }, 300); // 300ms pour la durée de l'animation
  };

  return (
    <div
      className={`relative min-h-screen bg-background ${
        isExiting ? "slide-out-right" : "slide-in-right"
      }`}
    >
      <div className="fixed top-0 left-0 w-full bg-background z-10 p-4">
        <div
          className={`flex justify-between items-center ${
            isNative
              ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
              : ""
          }`}
        >
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full w-12 h-12"
            onClick={handleBackClick}
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>
          <h1 className="text-xl font-semibold">Tableau de bord</h1>
          <div className="w-12 h-12"></div>{" "}
          {/* Espace vide pour maintenir l'alignement */}
        </div>
      </div>
      <div
        className="p-4 mt-4"
        style={{ paddingTop: "calc(4rem + 1px)", paddingBottom: "4rem" }}
      >
        <div className="flex space-x-2 mb-2 overflow-x-auto">
          <Button
            variant={selectedPeriod === "daily" ? "default" : "outline"}
            onClick={() => setSelectedPeriod("daily")}
          >
            Jour
          </Button>
          <Button
            variant={selectedPeriod === "monthly" ? "default" : "outline"}
            onClick={() => setSelectedPeriod("monthly")}
          >
            Mois
          </Button>
          <Button
            variant={selectedPeriod === "yearly" ? "default" : "outline"}
            onClick={() => setSelectedPeriod("yearly")}
          >
            Année
          </Button>
          <Button
            variant={selectedPeriod === "all-time" ? "default" : "outline"}
            onClick={() => setSelectedPeriod("all-time")}
          >
            Total
          </Button>
        </div>
        <p className="text-sm text-muted-foreground mb-6 mt-2">
          Les pourcentages indiqués se réfèrent à la période enregistrée
          précédente.
        </p>

        <div
          className={`grid grid-cols-1 md:grid-cols-2 ${
            isNative ? "gap-1" : "gap-4"
          } mb-8`}
        >
          <StatCard
            title="Nombre total de clients"
            data={currentData.totalClients}
            icon={<Users className="h-6 w-6" />}
            category="totalClients"
            onSelect={setSelectedCategory}
            isSelected={selectedCategory === "totalClients"}
            period={selectedPeriod}
          />
          <StatCard
            title="Nouveaux clients"
            data={currentData.newSignUps}
            icon={<TrendingUp className="h-6 w-6" />}
            category="newSignUps"
            onSelect={setSelectedCategory}
            isSelected={selectedCategory === "newSignUps"}
            period={selectedPeriod}
          />
          <StatCard
            title="Points ajoutés"
            data={currentData.pointsAdded}
            icon={<Zap className="h-6 w-6" />}
            category="pointsAdded"
            onSelect={setSelectedCategory}
            isSelected={selectedCategory === "pointsAdded"}
            period={selectedPeriod}
          />
          <StatCard
            title="Cartes complétées"
            data={currentData.redemptions}
            icon={<Repeat className="h-6 w-6" />}
            category="redemptions"
            onSelect={setSelectedCategory}
            isSelected={selectedCategory === "redemptions"}
            period={selectedPeriod}
          />
        </div>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>
              {selectedCategory === "totalClients" && "Nombre total de clients"}
              {selectedCategory === "newSignUps" && "Nouveaux clients"}
              {selectedCategory === "pointsAdded" && "Points ajoutés"}
              {selectedCategory === "redemptions" && "Cartes complétées"}{" "}
              {selectedPeriod === "daily"
                ? "(31 derniers jours)"
                : selectedPeriod === "monthly"
                ? "(24 derniers mois)"
                : selectedPeriod === "yearly"
                ? "(annuel)"
                : "(tous les temps)"}
            </CardTitle>
          </CardHeader>
          <CardContent>
            {currentData[selectedCategory].byPeriod.length > 0 ? (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={currentData[selectedCategory].byPeriod}>
                  <XAxis
                    dataKey="label"
                    stroke="#888888"
                    fontSize={10}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) =>
                      formatChartDate(value, selectedPeriod)
                    }
                    angle={-45}
                    textAnchor="end"
                    height={70}
                  />
                  <YAxis
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) => `${value}`}
                  />
                  <Bar
                    dataKey="value"
                    fill="#000000"
                    radius={[4, 4, 0, 0]}
                    cursor="default"
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="value"
                      position="top"
                      fontSize={10}
                      formatter={(value: number) =>
                        value === 0 ? "" : value.toString()
                      }
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <p>Aucune donnée disponible pour cette période.</p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AnalyticsPage;
