import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  increment,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db, auth, app } from "../firebase";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../@/components/ui/card";
import { X } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Switch } from "../@/components/ui/switch";
import { Label } from "../@/components/ui/label";
import { Capacitor } from "@capacitor/core";
import { getCountryCode } from "../utils/countryUtils";
import { getFunctions, httpsCallable } from "firebase/functions";

interface GeocodingResponse {
  lat: number;
  lng: number;
}

const categories = [
  { value: "Restaurant/Bar", label: "Restaurant et bar" },
  { value: "Commerce", label: "Commerce" },
  { value: "Santé/Beauté", label: "Santé et beauté" },
  { value: "Hôtel/Logement", label: "Hôtel et logement" },
  { value: "Fitness", label: "Fitness" },
  { value: "Culture/Loisirs", label: "Culture et loisirs" },
  { value: "Éducation", label: "Éducation" },
  { value: "Transport", label: "Transport" },
  { value: "Technologie/Innovation", label: "Technologie et innovation" },
  { value: "Artisanat", label: "Artisanat" },
  { value: "Environnement", label: "Environnement" },
  { value: "Services/Publics", label: "Services publics" },
  { value: "Finances/Assurance", label: "Finances et assurance" },
  { value: "Immobilier", label: "Immobilier" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Alimentation", label: "Alimentation" },
  { value: "Tourisme", label: "Tourisme" },
  { value: "Mode", label: "Mode" },
  { value: "Divertissement", label: "Divertissement" },
  { value: "Télécommunication", label: "Télécommunication" },
  { value: "Marketing/Publicité", label: "Marketing et publicité" },
  { value: "Juridique", label: "Juridique" },
  { value: "Ressources Humaines", label: "Ressources humaines" },
  { value: "Sécurité", label: "Sécurité" },
  { value: "Industrie", label: "Industrie" },
  { value: "Logistique", label: "Logistique" },
  { value: "Énergie", label: "Énergie" },
  { value: "Informatique", label: "Informatique" },
  { value: "Médias", label: "Médias" },
  { value: "Pharmaceutique", label: "Pharmaceutique" },
  { value: "Automobile", label: "Automobile" },
  { value: "Sport", label: "Sport" },
  { value: "Autre", label: "Autres" },
];

const updateShopSchema = z.object({
  name: z.string().min(1, "Le nom est requis"),
  category: z.string().min(1, "La catégorie est requise"),
  address: z.string().min(5, "L'adresse doit contenir au moins 5 caractères"),
  city: z.string().min(1, "La ville est requise"),
  postalCode: z.string().min(1, "Le code postal est requis"),
  country: z.string().min(1, "Le pays est requis"),
  canton: z.string().optional(),
  description: z
    .string()
    .max(200, "La description ne doit pas dépasser 200 caractères")
    .optional(),
  website: z.string().url("URL invalide").optional().or(z.literal("")),
  phone: z.string().regex(/^\+?[1-9]\d{1,14}$/, "Numéro de téléphone invalide"),
  instagram: z
    .string()
    .url("URL Instagram invalide")
    .optional()
    .or(z.literal("")),
  facebook: z
    .string()
    .url("URL Facebook invalide")
    .optional()
    .or(z.literal("")),
  showPhone: z.boolean(),
  showDescription: z.boolean(),
  showWebsite: z.boolean(),
  showInstagram: z.boolean(),
  showFacebook: z.boolean(),
  bubbleCount: z.number().min(1).max(20),
  offre: z
    .string()
    .min(1, "L'offre est requise")
    .max(160, "L'offre ne doit pas dépasser 160 caractères"),
});

type UpdateShopFormData = z.infer<typeof updateShopSchema>;

const EditMerchantPage: React.FC = () => {
  const navigate = useNavigate();
  const { shopId } = useParams<{ shopId: string }>();
  const [isSwiss, setIsSwiss] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  const [isUpdating, setIsUpdating] = useState(false);
  const [dots, setDots] = useState("");
  const [originalData, setOriginalData] = useState<UpdateShopFormData | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<UpdateShopFormData>({
    resolver: zodResolver(updateShopSchema),
  });

  useEffect(() => {
    const fetchMerchantProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const docRef = doc(db, "merchants", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data() as UpdateShopFormData;
            reset(data);
            setOriginalData(data);
            setIsSwiss(
              [
                "suisse",
                "schweiz",
                "svizzera",
                "switzerland",
                "svizra",
              ].includes(data.country.toLowerCase())
            );
          } else {
            console.error("No such document! User ID:", user.uid);
            navigate("/merchant-profiles");
          }
        } catch (error) {
          console.error("Error fetching merchant profile:", error);
        }
      } else {
        console.error("User not authenticated");
        navigate("/login");
      }
    };

    fetchMerchantProfile();
  }, [reset, navigate]);

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setIsSwiss(
      ["suisse", "schweiz", "svizzera", "switzerland", "svizra"].includes(value)
    );
  };

  const handleUpdateProfile = async (data: UpdateShopFormData) => {
    setIsUpdating(true);
    const user = auth.currentUser;
    if (!user) {
      alert("Vous devez être connecté pour effectuer cette action.");
      setIsUpdating(false);
      return;
    }

    try {
      const merchantRef = doc(db, "merchants", user.uid);
      const merchantDoc = await getDoc(merchantRef);

      if (!merchantDoc.exists()) {
        throw new Error("Document marchand non trouvé");
      }

      const merchantData = merchantDoc.data();
      const now = Timestamp.now();
      const today = new Date(now.toDate().setHours(0, 0, 0, 0));

      if (
        merchantData.lastUpdateDate &&
        merchantData.lastUpdateDate.toDate() >= today
      ) {
        if (merchantData.updateCount >= 2) {
          alert(
            "Vous avez atteint la limite de mises à jour pour aujourd'hui. Réessayez demain."
          );
          setIsUpdating(false);
          return;
        }
      }

      let geocodeResult: GeocodingResponse | null = null;

      // Check if address-related fields have changed
      if (
        originalData &&
        (data.address !== originalData.address ||
        data.city !== originalData.city ||
        data.postalCode !== originalData.postalCode ||
        (isSwiss && data.canton !== originalData.canton))
      ) {
        // Préparer les données d'adresse pour le géocodage
        const addressData = {
          address: data.address,
          postalCode: data.postalCode,
          city: data.city,
          country: data.country,
          canton: isSwiss ? data.canton : undefined,
        };

        // Appeler la fonction Cloud pour le géocodage
        const functions = getFunctions(app, "europe-west6");
        const geocodeAddress = httpsCallable<
          typeof addressData,
          GeocodingResponse
        >(functions, "geocodeAddress");
        const result = await geocodeAddress(addressData);
        geocodeResult = result.data;
      }

      const updateData = {
        ...data,
        userId: user.uid,
        bubbleCount: Number(data.bubbleCount),
        category: data.category,
        lastUpdateDate: now,
        updateCount:
          merchantData.lastUpdateDate &&
          merchantData.lastUpdateDate.toDate() >= today
            ? increment(1)
            : 1,
        ...(geocodeResult && {
          latitude: geocodeResult.lat,
          longitude: geocodeResult.lng,
        }),
      };

      if (!isSwiss) {
        delete updateData.canton;
      }

      const shopId = merchantData.shopId;

      // Mise à jour du document merchant
      await updateDoc(merchantRef, updateData);

      // Mise à jour du magasin global
      const countryCode = getCountryCode(data.country);
      const globalShopRef = doc(
        db,
        "globalShops",
        "countries",
        countryCode,
        shopId
      );
      await updateDoc(globalShopRef, updateData);

      // Récupérer la liste des userIds à mettre à jour
      const analyticsRef = doc(db, "analytics", shopId);
      const userCountsDoc = await getDoc(
        doc(analyticsRef, "userCounts", "totalList")
      );
      const userIds = userCountsDoc.data()?.userIds || [];

      const fieldsToUpdate = [
        "name",
        "category",
        "address",
        "city",
        "postalCode",
        "country",
        "canton",
        "description",
        "offre",
        "website",
        "phone",
        "instagram",
        "facebook",
        "showDescription",
        "showPhone",
        "showWebsite",
        "showInstagram",
        "showFacebook",
        "latitude",
        "longitude",
      ];

      const BATCH_SIZE = 100; // Taille réduite du lot
      let batch = writeBatch(db);
      let batchCount = 0; // Nouveau compteur pour les éléments dans le lot actuel
      let totalUpdateCount = 0; // Compteur pour le nombre total de mises à jour
      let successCount = 0;
      let errorCount = 0;

      for (const userId of userIds) {
        const shopRef = doc(db, "wallets", userId, "wallet", shopId);
        const shopDocSnap = await getDoc(shopRef);

        if (shopDocSnap.exists()) {
          const shopData = shopDocSnap.data();
          const updatedShop = {
            ...Object.fromEntries(
              Object.entries(updateData).filter(([key]) =>
                fieldsToUpdate.includes(key)
              )
            ),
          };

          // Gérer les différents cas de mise à jour du bubbleCount
          if (shopData.progression === 0) {
            // Si la carte n'est pas commencée, mettre à jour le bubbleCount
            updatedShop.bubbleCount = updateData.bubbleCount;
          } else {
            // Si la carte est commencée, garder l'ancien bubbleCount
            updatedShop.bubbleCount = shopData.bubbleCount;
            // Ajouter un champ pour stocker le nouveau bubbleCount pour les futures cartes
            updatedShop.newBubbleCount = updateData.bubbleCount;
          }

          try {
            batch.update(shopRef, updatedShop);
            batchCount++;
            successCount++;
            totalUpdateCount++;

            if (batchCount === BATCH_SIZE) {
              await batch.commit();
              batch = writeBatch(db);
              batchCount = 0; // Réinitialiser le compteur de lot
            }
          } catch (error) {
            console.error(
              `Erreur lors de la mise à jour pour l'utilisateur ${userId}:`,
              error
            );
            errorCount++;
          }
        }
      }

      // Ne pas oublier de commiter le dernier lot s'il reste des éléments
      if (batchCount > 0) {
        await batch.commit();
      }

      //console.log(`Mises à jour totales : ${totalUpdateCount}`);
      //console.log(`Mises à jour réussies : ${successCount}`);
      //console.log(`Erreurs : ${errorCount}`);

      setIsUpdating(false);
      alert("Profil du commerce mis à jour avec succès !");
      navigate("/merchant-profiles");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil :", error);
      alert("Une erreur est survenue lors de la mise à jour du profil.");
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (isUpdating) {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + "." : ""));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isUpdating]);

  return (
    <div
      className={`fixed inset-0 flex flex-col bg-background ${
        isNative
          ? "safe-area-inset-top safe-area-inset-left safe-area-inset-right"
          : ""
      }`}
    >
      <div className="bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <div className="w-12 h-12"></div>{" "}
          {/* Espace vide pour maintenir l'alignement */}
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full w-12 h-12"
            onClick={() =>
              navigate("/merchant-profiles", { state: { noAnimation: true } })
            }
          >
            <X className="h-6 w-6" />
          </Button>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto p-4">
        <Card>
          <CardHeader>
            <CardTitle>Modifier le profil du commerce</CardTitle>
          </CardHeader>
          <CardContent>
            <form
              onSubmit={handleSubmit(handleUpdateProfile)}
              className="space-y-4 pb-20"
            >
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom
                </label>
                <Input
                  id="name"
                  {...register("name")}
                  className="mt-1 h-12"
                  placeholder="Nom du commerce"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name.message}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Catégorie du commerce
                </label>
                <select
                  {...register("category")}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 bg-gray-100 border focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md h-12"
                >
                  <option value="">Veuillez sélectionner une catégorie</option>
                  {categories.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </select>
                {errors.category && (
                  <p className="text-red-500 text-sm">
                    {errors.category.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adresse
                </label>
                <Input
                  id="address"
                  {...register("address")}
                  className="mt-1 h-12"
                  placeholder="Adresse"
                />
                {errors.address && (
                  <p className="text-red-500 text-sm">
                    {errors.address.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ville
                </label>
                <Input
                  id="city"
                  {...register("city")}
                  className="mt-1 h-12"
                  placeholder="Ville"
                />
                {errors.city && (
                  <p className="text-red-500 text-sm">{errors.city.message}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="postalCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Code postal
                </label>
                <Input
                  id="postalCode"
                  {...register("postalCode")}
                  className="mt-1 h-12"
                  placeholder="Code postal"
                />
                {errors.postalCode && (
                  <p className="text-red-500 text-sm">
                    {errors.postalCode.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Pays
                </label>
                <Input
                  id="country"
                  {...register("country")}
                  className="mt-1 h-12 bg-gray-100"
                  placeholder="Pays"
                  disabled
                />
                <a
                  href="mailto:team@fidelipassapp.com"
                  className="text-sm text-gray-600 hover:underline"
                >
                  Pour changer de pays, contactez-nous en cliquant ici
                </a>
              </div>

              {isSwiss && (
                <div>
                  <label
                    htmlFor="canton"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Canton
                  </label>
                  <Input {...register("canton")} placeholder="Canton" />
                  {errors.canton && (
                    <p className="text-red-500 text-sm">
                      {errors.canton.message}
                    </p>
                  )}
                </div>
              )}

              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <Input
                  id="description"
                  {...register("description")}
                  className="mt-1 h-12"
                  placeholder="Description (max 160 caractères)"
                />
                {errors.description && (
                  <p className="text-red-500 text-sm">
                    {errors.description.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="website"
                  className="block text-sm font-medium text-gray-700"
                >
                  Site Internet
                </label>
                <Input
                  id="website"
                  {...register("website")}
                  className="mt-1 h-12"
                  placeholder="Site web"
                />
                {errors.website && (
                  <p className="text-red-500 text-sm">
                    {errors.website.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Numéro de téléphone (indicateur et tout collé)
                </label>
                <Input
                  id="phone"
                  {...register("phone")}
                  className="mt-1 h-12"
                  placeholder="Ex: +41787907979"
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone.message}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="instagram"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lien Instagram
                </label>
                <Input
                  id="instagram"
                  {...register("instagram")}
                  className="mt-1 h-12"
                  placeholder="Lien Instagram"
                />
                {errors.instagram && (
                  <p className="text-red-500 text-sm">
                    {errors.instagram.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="facebook"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lien Facebook
                </label>
                <Input
                  id="facebook"
                  {...register("facebook")}
                  className="mt-1 h-12"
                  placeholder="Lien Facebook"
                />
                {errors.facebook && (
                  <p className="text-red-500 text-sm">
                    {errors.facebook.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="bubbleCount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nombre de tampons
                </label>
                <select
                  id="bubbleCount"
                  {...register("bubbleCount", {
                    valueAsNumber: true,
                    required: "Le nombre de tampons est requis",
                  })}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 bg-gray-100 border focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md h-12"
                >
                  {[...Array(20)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
                {errors.bubbleCount && (
                  <p className="text-red-500 text-sm">
                    {errors.bubbleCount.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="offre"
                  className="block text-sm font-medium text-gray-700"
                >
                  Produit obtenu après carte remplie
                </label>
                <Input
                  id="offre"
                  {...register("offre")}
                  className="mt-1 h-12"
                  placeholder="Qu'offrez-vous au client après la complétion d'une carte ?"
                  required
                />
                {errors.offre && (
                  <p className="text-red-500 text-sm">{errors.offre.message}</p>
                )}
              </div>

              <div className="block text-sm font-medium text-gray-700">
                <p>
                  Sur le profil de votre commerce, vous avez le choix d'afficher
                  les éléments suivants:
                </p>
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showPhone" className="flex-grow">
                  Afficher le numéro de téléphone
                </Label>
                <Switch
                  id="showPhone"
                  {...register("showPhone")}
                  className="scale-125"
                />
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showDescription" className="flex-grow">
                  Afficher la description
                </Label>
                <Switch
                  id="showDescription"
                  {...register("showDescription")}
                  className="scale-125"
                />
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showWebsite" className="flex-grow">
                  Afficher le site web
                </Label>
                <Switch
                  id="showWebsite"
                  {...register("showWebsite")}
                  className="scale-125"
                />
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showInstagram" className="flex-grow">
                  Afficher Instagram
                </Label>
                <Switch
                  id="showInstagram"
                  {...register("showInstagram")}
                  className="scale-125"
                />
              </div>

              <div className="flex items-center justify-between py-2">
                <Label htmlFor="showFacebook" className="flex-grow">
                  Afficher Facebook
                </Label>
                <Switch
                  id="showFacebook"
                  {...register("showFacebook")}
                  className="scale-125"
                />
              </div>

              <Button
                type="submit"
                className="w-full mt-6 h-12"
                disabled={isUpdating}
              >
                {isUpdating ? <>Mise à jour{dots}</> : "Mettre à jour"}
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EditMerchantPage;
