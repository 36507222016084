"use client";
import * as React from "react";
import { Check } from "lucide-react";
import { cn } from "src/@/lib/utils";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, ...props }, ref) => {
    return (
      <div className="relative inline-flex items-center justify-center w-5 h-5">
        <input
          type="checkbox"
          ref={ref}
          className={cn("peer sr-only", className)}
          {...props}
        />
        <div className="h-5 w-5 border border-primary rounded-sm bg-background transition-colors peer-checked:bg-primary peer-checked:border-primary peer-focus-visible:ring-2 peer-focus-visible:ring-ring peer-focus-visible:ring-offset-2 peer-disabled:cursor-not-allowed peer-disabled:opacity-50 flex items-center justify-center">
          <Check className="h-3 w-3 text-background peer-checked:block" />
        </div>
        <label
          htmlFor={props.id}
          className="absolute inset-0 w-full h-full cursor-pointer"
        />
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export { Checkbox };
