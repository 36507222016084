import React from 'react';
import { Navigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import WalletPage from '../pages/WalletPage';
import Spinner from '../@/components/ui/spinner';
import { useQuery } from '@tanstack/react-query';

const WalletPageWrapper: React.FC = () => {
  const { data: onboardingCompleted, isLoading } = useQuery({
    queryKey: ['onboarding-status'],
    queryFn: async () => {
      const user = auth.currentUser;
      if (!user) return false;

      const storedOnboardingStatus = localStorage.getItem('onboardingCompleted');
      if (storedOnboardingStatus !== null) {
        return storedOnboardingStatus === 'true';
      }

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const isCompleted = userDocSnap.exists() ? (userDocSnap.data().onboardingCompleted || false) : false;
      localStorage.setItem('onboardingCompleted', isCompleted.toString());
      return isCompleted;
    },
    staleTime: 7 * 24 * 60 * 60 * 1000, // 7 jours
    gcTime: 7 * 24 * 60 * 60 * 1000, // 7 jours
    retry: false
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (onboardingCompleted === false) {
    return <Navigate to="/onboarding" />;
  }

  return <WalletPage />;
};

export default WalletPageWrapper;