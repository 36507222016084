import React from "react";
import { Button } from "../@/components/ui/button";
import { Search, RefreshCw } from "lucide-react";
import { ReactComponent as FideliPassLogo } from "../assets/FideliPass nom.svg";
import { Input } from "../@/components/ui/input";

interface HeaderProps {
    variant?: "default" | "search" | "refresh";
    onRefresh?: () => void;
    isRefreshing?: boolean;
    className?: string; 
    searchProps?: {
      isSearchOpen: boolean;
      searchTerm: string;
      onSearchChange: (value: string) => void;
      onSearchToggle: () => void;
      onSearchBlur?: () => void;
      onSearchFocus?: () => void;
      onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // Mise à jour du type ici
      searchInputRef?: React.RefObject<HTMLInputElement>;
    };
  }

export const Header: React.FC<HeaderProps> = ({
  variant = "default",
  onRefresh,
  isRefreshing,
  searchProps,
  className = "",
}) => {
  return (
    <div className={`bg-white p-4 ${className}`}>
      <div className="flex justify-between items-center">
        {variant === "search" && searchProps ? (
          <>
            <div
              className={`transition-opacity duration-300 ${
                searchProps.isSearchOpen ? "hidden" : "block"
              }`}
            >
              <FideliPassLogo className="h-5 w-auto" />
            </div>
            <div
              className={`relative flex-grow transition-all duration-300 ${
                searchProps.isSearchOpen ? "w-full" : "w-auto"
              }`}
            >
              <Input
                ref={searchProps.searchInputRef}
                type="text"
                placeholder="Cherchez un magasin, une ville, une catégorie..."
                value={searchProps.searchTerm}
                onChange={(e) => searchProps.onSearchChange(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => searchProps.onKeyDown?.(e)} // Mise à jour ici
                className={`pl-2 pr-10 py-1 w-full h-12 transition-all duration-300 ${
                  searchProps.isSearchOpen ? "opacity-100" : "opacity-0 w-0"
                }`}
                onBlur={searchProps.onSearchBlur}
                onFocus={searchProps.onSearchFocus}
              />
              <Button
                variant="ghost"
                size="icon"
                className="absolute right-0 top-1/2 transform -translate-y-1/2 h-12 w-12 rounded-full"
                onClick={searchProps.onSearchToggle}
              >
                <Search className="h-6 w-6" />
              </Button>
            </div>
          </>
        ) : (
          <>
            <FideliPassLogo className="h-5 w-auto" />
            {variant === "refresh" ? (
              <Button
                variant="ghost"
                size="icon"
                className="w-12 h-12 rounded-full"
                onClick={onRefresh}
                disabled={isRefreshing}
              >
                <RefreshCw
                  className={`h-6 w-6 ${isRefreshing ? "animate-spin" : ""}`}
                />
              </Button>
            ) : (
              <div className="w-12 h-12"></div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
